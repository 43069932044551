.customer-portal-page {
    padding-top: 80px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        padding-top: 60px;
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        padding-top: 60px;
    }
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        padding-top: 60px;
    }
    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding-top: 60px;
    }
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width:767px) {
        padding-top: 60px;
    }
    .hr-line {
        height: 1px;
        background: #0b86c0;
        margin: 15px 30px 15px 15px;
    }
    .tab-content {
        width: 100%;
        padding-left: 240px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            padding-left: 0px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            padding-left: 0px;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            padding-left: 0px;
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-left: 0px;
        }
    }
}