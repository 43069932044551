.login-section {
    .login-block {
        padding-top: 80px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 60px;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 60px;
        }
        @media (min-width: 481px) and (max-width: 767px) {
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 60px;
        }
        // (Portrait)
        @media (min-width: 320px) and (max-width: 480px) {
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 60px;
        }
        .right-blk {
            .image-container {
                height: 600px;
                width: 780px;
                position: relative;
                display: flex;
                justify-content: center;
                .bg-gradient {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    right: 0;
                    height: 600px;
                    width: 780px;
                    background: transparent linear-gradient(180deg, #51515C00 70%, #51515C 100%) 0% 0% no-repeat padding-box;
                }
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                display: none;
            }
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                display: none;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                display: none;
            }
        }
        .left-blk {
            padding: 20px 50px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                padding: 50px 60px;
            }
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                padding: 40px 188px;
            }
            @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 20px 30px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 30px 30px;
            }
            @media only screen and (min-width: 1919px) {
                padding: 30px 80px;
            }
            .quick-links {
                padding: 0px 16px;
                .quick-links-body {
                    .facility {
                        padding: 50px 16px 30px;
                        .box {
                            border-color: #C2E2F2;
                            margin: 12px 0px;
                            padding: 0px 18px;
                            border-color: $color-border;
                            height: 60px;
                            text-align: center;
                            &.border-md-left {
                                border-left: 1px solid #C2E2F2;
                            }
                            &.border-md-right {
                                border-right: 1px solid #C2E2F2;
                            }
                            @media (min-width: 320px) and (max-width: 480px) {
                                &.border-md-left {
                                    border-left: none;
                                }
                                &.border-md-right {
                                    border-right: none;
                                }
                                &.border-sm-left {
                                    border-left: 1px solid #C2E2F2;
                                }
                            }
                            @media (min-width: 481px) and (max-width: 767px) {
                                &.border-md-left {
                                    border-left: none;
                                }
                                &.border-md-right {
                                    border-right: none;
                                }
                                &.border-sm-left {
                                    border-left: 1px solid #C2E2F2;
                                }
                            }
                        }
                        .block {
                            height: 90px;
                            border-radius: 10px;
                            padding: 0;
                            @media (min-width: 481px) and (max-width: 767px) {
                                width: 50%;
                            }
                            // (Portrait)
                            @media (min-width: 320px) and (max-width: 480px) {
                                width: 50%;
                            }
                            &:hover {
                                background: #E3EEFC;
                            }
                            .link-name {
                                font-family: Lexend;
                                font-size: 13px;
                                font-weight: normal;
                                line-height: 1.29;
                                letter-spacing: -0.42px;
                                text-align: left;
                                color: $color-black;
                                padding-top: 12px;
                                text-align: center;
                            }
                            .link {
                                padding-bottom: 13px;
                            }
                        }
                    }
                    .get-in-touch {
                        .get-in-touch-sec {
                            .get-in-touch-text {
                                font-family: Lexend;
                                font-size: 16px;
                                font-weight: normal;
                                letter-spacing: -0.48px;
                                color: $color-grey;
                                text-align: center;
                                width: 320px;
                            }
                            .hr-line {
                                width: 100%;
                                height: 2px;
                                background-color: $color-border;
                            }
                        }
                        .get-in-touch-block {
                            padding: 10px 16px 30px;
                        }
                        .box {
                            margin: 12px 0px;
                            padding: 0px 18px;
                            border-color: $color-border;
                            height: 60px;
                            text-align: center;
                            &.border-md-left {
                                border-left: 1px solid #C2E2F2;
                            }
                            &.border-md-right {
                                border-right: 1px solid #C2E2F2;
                            }
                            @media (min-width: 320px) and (max-width: 480px) {
                                &.border-md-left {
                                    border-left: none;
                                }
                                &.border-md-right {
                                    border-right: none;
                                }
                                &.border-sm-right {
                                    border-right: 1px solid #C2E2F2;
                                }
                            }
                            @media (min-width: 481px) and (max-width: 767px) {
                                &.border-md-left {
                                    border-left: none;
                                }
                                &.border-md-right {
                                    border-right: none;
                                }
                                &.border-sm-right {
                                    border-right: 1px solid #C2E2F2;
                                }
                            }
                        }
                        .block {
                            height: 90px;
                            padding: 0px;
                            @media (min-width: 481px) and (max-width: 767px) {
                                width: 50%;
                            }
                            // (Portrait)
                            @media (min-width: 320px) and (max-width: 480px) {
                                width: 50%;
                            }
                            &:hover {
                                background: #E3EEFC;
                                border-radius: 10px;
                            }
                            .link-name {
                                font-family: "Nunito Sans" !important;
                                font-size: 13px;
                                font-weight: normal;
                                line-height: 1.33;
                                letter-spacing: -0.24px;
                                text-align: left;
                                color: #4f5266;
                                padding-top: 12px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .signin-signup {
                padding: 30px 26px;
                // *different techniques for iPad screening */
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    padding: 40px 30px 0px;
                }
                @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                    padding: 40px 72px 0px;
                }
                @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 40px 0px 0px;
                }
                .help-text {
                    color: $color-grey;
                    font-size: 11px;
                    @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: none;
                    }
                }
                .login-to-account {
                    padding: 24px 10px 40px 10px;
                    text-align: center;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        padding: 40px 10px 40px 10px;
                    }
                    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                        padding: 40px 10px 40px 10px;
                    }
                    &.sign-up {
                        .login-info {
                            padding-bottom: 20px;
                        }
                    }
                    .heading {
                        padding-bottom: 4px;
                        color: #4d4d5c;
                        font-weight: 600;
                    }
                    .login-info {
                        color: $color-grey;
                    }
                    .social-media-btn-block {
                        padding: 20px 0px 28px;
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            padding: 40px 0px 28px;
                        }
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            padding: 40px 0px 28px;
                        }
                        // (Landscape) 
                        @media (min-width: 481px) and (max-width: 767px) {}
                        // (Portrait)
                        @media (min-width: 320px) and (max-width: 480px) {
                            display: block !important;
                            .btn-social-media {
                                margin: 0px !important;
                                width: 197px;
                                &.facebook-btn {
                                    margin-top: 12px !important;
                                }
                            }
                        }
                    }
                    .or-block {
                        padding-bottom: 28px;
                        .or-text {
                            font-size: 10px;
                            color: #7D7D8C;
                        }
                        .hr-line {
                            background: $color-border;
                            height: 1px;
                            width: 100%;
                        }
                    }
                    .sign-in-btn {
                        margin-top: 10px;
                        height: 35px;
                    }
                    .forgot-text {
                        font-size: 12px;
                        color: $color-blue;
                        padding-top: 8px;
                    }
                }
                .refresh-captcha {
                    height: 48px !important;
                    background: $color-white !important;
                    border: 1px solid #c2e2f2 !important;
                    border-radius: 10px !important;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    width: 134px;
                    margin-right: 10px;
                    .refresh-icon {
                        margin-left: 6px;
                        cursor: pointer;
                    }
                }
                .custom-checkbox {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

.toggle-password {
    height: 24px;
    width: 24px;
}