.card{
    &.card-white{
        border: 1px solid $color-border;
        box-shadow: $card-primary-bs;
        border-radius: 10px;
    }
    &.card-blue{
        background: $gradient-blue;
        box-shadow: $icon-bs;
        border-radius: 10px;
    }
}