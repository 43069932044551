.nominee-section {
    .card{
        margin-bottom: 0px !important;
        margin-top: 10x;
        &.card-white{
            padding:20px; 
            color: $color-grey3;
            box-shadow: none;
        }
        .nominetitle{
            font-size: 16px;
            color: $color-light-black;
            font-weight: 600;
        }
        .nominedes{
            font-size: 12px;
            color: $color-grey;
        }
    }

    .card{
      margin-bottom: 0px !important;
      margin-top: 10x;
      &.card-upload{
          padding:10px; 
          color: $color-grey3;
          box-shadow: none;
      }
      .nominetitle{
          font-size: 16px;
          color: $color-light-black;
          font-weight: 600;
      }
      .nominedes{
          font-size: 12px;
          color: $color-grey;
      }
  }
    /*horizental line with center text*/
    .or-block {
        .or-text {
            font-size: 12px;
            color: #7D7D8C;
            white-space: nowrap;
            font-weight: 700;
            padding: 0 10px;
        }
        .hr-line {
            background: $color-border;
            height: 1px;
            width: 100%;
            margin: 15px 0px !important;
        }
    }
    .radio-extramarg{
        padding-top:10px;
    }
    .nominetitlepad{
      padding: 12px 8px 0px 8px;
    }
    .whitecard-radio label{
      padding: 0px 8px 8px 3px !important;
    }
    .whitecard-radio label span{
      padding: 12px !important;
    }
    .nomine-custom-p-5{
      padding: 10px 8px 0px 8px;
      height: 45px;
      @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 0px 0px 0px 0px !important;
      }
     @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 0px 0px 0px 0px !important;
      }
    }
  }

.nominee-upload{
  .upload-box {
      margin-top: 20px;
    label {
      color: #074aa3;
      font-size: 12px;
      font-family: "Lexend";
      font-weight: 600;
      cursor: pointer;
      border: 1px dashed #b9ddf0;
      height: 160px;
      width: 80%;
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
    }
    &.height-change {
      label {
        height: 87px;
      }
    }
}

.reupload-box {
  margin-top: 20px;
  label {
  background-color: $color-light-blue2;
  color: #074aa3;
  font-size: 12px;
  font-family: "Lexend";
  font-weight: 600;
  cursor: pointer;
  height: 160px;
  width:80%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  }
  .mr5{
    margin-right: 5px;
  }
  .uploadimgtitle{
    margin-bottom: 25px;
    font-size: 12px !important;
    font-weight: normal !important;
  }
  &.height-change {
    label {
    height: 87px;
    }
  }
  }
}
.mr2{
  margin-right: 2px;
}
.mb6{
  padding-bottom: 6px;
}
.nominee-c{
  font-weight: 700 !important;
}