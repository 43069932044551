.accordion {
    .card {
        border: 1px solid $color-border !important;
        border-radius: 10px !important;
        margin-bottom: 10px !important;
        .card-header {
            border-bottom: 1px solid $color-border;
            padding: 28px 30px;
            background: none;
            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 20px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 20px;
            }
            .btn-link {
                padding: 0px;
                .acc-arrow {
                    margin-left: 12px;
                    content: url("./../assets/images/arrow.svg");
                    transform: rotate(0deg);
                    &:hover {
                        background: #E3EEFC;
                        border-radius: 50%;
                    }
                }
                &.collapsed {
                    .acc-arrow {
                        transform: rotate(180deg) !important;
                    }
                }
            }
            .btn:focus,
            .btn.focus {
                outline: 0;
                box-shadow: none;
            }
            .btn-link:focus,
            .btn-link.focus {
                text-decoration: none;
            }
            .btn-link:hover {
                color: unset;
                text-decoration: none;
            }
        }
    }
}