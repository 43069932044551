section .section-wrapper {
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    .tooltip {
        position: relative !important;
        display: inline-block !important;
        opacity: 1 !important;
        width: 25px;
      }
      .tooltip .tooltiptext {
        visibility: hidden;
        color: $color-grey;
        text-align: center;
        padding: 5px;
        font-size: 8px;
        
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
      }

      /* Tooltipright */
      .tooltip-right {
        position: relative !important;
        display: inline-block !important;
        opacity: 1 !important;
      }

      .tooltip-right .duetextpending {
        visibility: hidden;
        color: $color-grey;
        text-align:left;
        padding:10px;
        font-size:10px;
        background-color: #FFE8E6;
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top:-15px;
        border-radius: 10px;
        width: 220px;
        margin-left: 15px;
      }
      .duetextpending:after, .duetextpending:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    .duetextpending:after {
        border-color: rgba(136, 183, 213, 0);
        border-right-color: #FFE8E6;
        border-width: 10px;
        margin-top: -10px;
    }
      
      .tooltip-right:hover .duetextpending {
        visibility: visible;
      }
}
.section-wrapper1 {
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    color:$color-primary;
}

.line {
    width: 100%;
    position: relative;
    z-index: 1030;
}



.timeline-edge-wrapper {
    background: linear-gradient(181deg, #00d351 99%, #13a651 0%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 1px;
    margin-right: 10px;
}


.timeline-edge-wrapper::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    background: linear-gradient(181deg, #00d351 99%, #13a651 0%);
    bottom: -20px;
    left: 35%;
}

.timeline-edge-wrapper-end {
    background-color: #CCD1D9;

    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    right: 6px;
}
.timeline-edge-wrapper-end::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    background-color: #CCD1D9;
    bottom: -20px;
    right: 35%;
}


.timeline-edge-wrapper-end1 {
    background-color: #CCD1D9;

    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 120px;
}
.timeline-edge-wrapper-end1::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    background-color: #CCD1D9;
    bottom: -20px;
    right: 35%;
}


.line:hover {
    cursor: pointer;
}

.line .year {
    background-color: #CCD1D9;
    border-radius: 20%;
    float: left;
    position: relative;
    // left: 4.3%;
    box-sizing: border-box;
    margin-right: 2px;
    margin-left: 2px;
    width: 26px;
    height: 4px;
    top: -5px;
}

.line .quarter {
    background-color: #CCD1D9;
    border-radius: 5px;
    float: left;

    // left: 4.3%;
    box-sizing: border-box;
    margin-right: 1px;
    margin-left: 1px;
    width: 25px;
    height: 4px;
    top: -5px;
    position: fixed;
    z-index: 99;
}

.line .complete {
    background-color: #1BD351;
}

.line .dot.active {
    background-image: linear-gradient(to bottom, #0b86c1, #074aa3);
    cursor: pointer;
}

.timeline {
    padding-top: 30px;
    padding-bottom: 20px;
    color: #34495E;
    width:960px;
    z-index: 105;
    position:relative;
}

.description {
    min-width: 120px;
    position:absolute;
    left:0;
    top: 0;
}
.description p{
    margin-bottom:-1px !important;
    text-align: center;
    text-shadow: 3px 2px 4px #ffffff;
    background-color: white;
    width: 120px !important;
    margin-left: -56px;
}
.description .blue{
    color:$color-primary;
}
.description .red{
    color:$color-red !important;
}
.description h3 {
    color: #C0392B;
    font-weight: 600;
}

.description .section-wrapper>div {
    display: none;
}

.description .section-wrapper>div.text-default {
    display: block;
}

.description .section-wrapper1>div {
    display: none;
}

.description .section-wrapper1>div.text-default {
    display: block;
}


.policy-timeline {
    letter-spacing: 0.6px;
    color: $color-grey1;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 12px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        display: none;
      }
      @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        display: none;
      }
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        display: none;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
          display: none;
      }
}

.policy-reminder-year {
    border-radius: 5px;
    background-image: linear-gradient(to bottom, #0b86c1, #074aa3);
    width: 26px;
    height: 7px !important;
    top: -7px !important;
    margin: -12px auto 0 -10px;
    .policy-patch-year {
        position: relative;
        top: -20px;
        text-align: center;
        font-size: 8px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 24px;
        background-image: linear-gradient(to bottom, #0b86c1, #074aa3);
        top: 5px;
        left:12px;
    }
}

.policy-reminder-year-red {
    border-radius: 5px;
    background-image: linear-gradient(to bottom, #FE2626, #991717);
    width: 26px;
    height: 7px !important;
    top: -7px !important;
    margin: -12px auto 0 -10px;
    .policy-patch-year {
        position: relative;
        top: -20px;
        text-align: center;
        font-size: 8px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 24px;
        background-image: linear-gradient(to bottom, #FE2626, #991717);
        top: 5px;
        left:12px;
    }
}



.ending-year {

    .policy-patch-year {
        position: relative;
        top: -20px;
        text-align: center;
        font-size: 8px;
        font-weight: normal;
        left: 8px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

}


.payment-date-wrapper {
    display: flex;
    justify-content: space-between;
    .policy-comm {
        color: $color-grey1;
        font-weight: normal;
        font-size: 12px;
        margin-top: 20px;

        & span {
            font-size: 13px;
            font-weight: 800;

        }
    }
    .policy-comm1 {
        color: #E6EAF0;
        font-weight: normal;
        font-size: 12px;
        margin-top: 20px;

        & span {
            font-size: 13px;
            font-weight: 800;

        }
    }

    .payment-date-reminder {
        color: $color-blue;
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        margin-top: 20px;

        & span {
            font-size: 13px;
            font-weight: 800;

        }
    }
    .payment-maturity {
        float: right;
        text-align: right;
        font-weight: normal;
        color: #E6EAF0 !important;
    }

    .payment-ends {
        text-align: right;
        font-weight: normal;
        position:absolute;
        right: 0;
    }

   

}

.line-policy-maturity {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 39px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        margin-top: 13px;
      }
      @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        margin-top: 13px;
      }
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        margin-top: 13px;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        margin-top: 13px;
      }
    .policy-comm {
        color: #9A9AAD;
        font-weight: normal;
        font-size: 12px;
        margin-top: 10px;
        & span {
            font-size: 13px;
            font-weight: 800;

        }
    }
    p{
        padding-bottom: 0px;
    }
    .ending.year {
        background-color: #E6EAF0;
        float: left;
        position: relative;
        box-sizing: border-box;
        margin-right: 2px;
        margin-left: 2px;
        width:170px;
        height: 4px;
        top:22px;
        border-radius: 2px;
    }
    .timeline-start-dot{
        float: left;
        margin-top: 20px;
        text-align: right;
    }
    .start-text{
        position: absolute;
        left: 0;
        margin-left: -120px;
    }
    .end-text{
        position: absolute;
        right: 0;
        color: #9A9AAD !important;
    }
    .timeline-end-dot{
        margin-top: 20px;
        float: right;
        text-align: right;
        opacity: 0.5;
    }
}

.totaltime{
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        white-space: nowrap !important;
        overflow: scroll !important;
        overflow-y: hidden !important;
      }
      @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        white-space: nowrap;
        overflow: scroll;
        overflow-y: hidden;
      }
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        white-space: nowrap;
        overflow: scroll;
        overflow-y: hidden;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        white-space: nowrap;
        overflow: scroll;
        overflow-y: hidden;
      }

}
.firstp{
@include for-phone-only {
    text-align: left !important;
    margin-left: -20px !important;
    }
@include for-tablet-portrait-up{
    text-align: left !important;
    margin-left: -20px !important;
    }
}
