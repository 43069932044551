// custom bar chart stylings
.bar-chart {
    .progress-bar {
        float: left;
        height: 80px;
        width: 120px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            width: 107px;
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 78px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 78px;
        }
        margin-right: 6px;
        background: white;
    }
    .progress-track {
        position: relative;
        width: 120px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            width: 107px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            width: 107px;
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 78px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 78px;
        }
        height: 100%;
        background: white;
    }
    .progress-fill {
        position: relative;
        height: 50%;
        width: 120px;
        text-align: center;
        font-size: 12px;
        font-weight: 800;
        line-height: 20px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            width: 107px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            width: 107px;
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 78px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 78px;
        }
        &.green {
            background: #b2eec6;
            color: #14ab51;
        }
        &.blue {
            background: #fee0b2;
            color: #db8700
        }
        &.grey {
            background: #d2d2d9;
            color: #7b7b8a;
        }
        span {
            position: absolute;
            bottom: 0;
            width: 120px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                width: 107px;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                width: 107px;
            }
            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                width: 78px;
                font-size: 10px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                width: 78px;
                font-size: 10px;
            }
            text-align: center;
            left: 0;
            font-weight: 700;
            &.with-margin {
                bottom: 30px;
            }
        }
    }
}