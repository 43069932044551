/*  slider */
@property --result-color {
  syntax: "<color>";
  initial-value: transparent;
  inherits: false;
}
.doughnutcontainer {
	display: inline-block;
  height:200px;
  .block {
    float: left;
    width:100%;
    overflow: hidden;
  }
  .donut-chart-block {
    overflow: hidden;
  }
  .donut-chart {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0px auto;
    border-radius: 50%;
    .center {
      background: #fff;
      border-radius: 50%;
      height: 60%;
      left: 20%;
      position: absolute;
      top: 20%;
      width: 60%;
    }
  }
  .center {
    span {
      color: #7a7a7a;
      display: block;
      font-size: 36px;
      font-weight: 800;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
          display: none;
    }
  }
  .clip {
    border-radius: 50%;
    clip: rect(0px, 200px, 200px, 100px);
    height: 100%;
    position: absolute;
    width: 100%;
    transform: rotate(-90deg) !important;
  }
  .item {
    border-radius: 50%;
    clip: rect(0px, 100px, 200px, 0px);
    height: 100%;
    position: absolute;
    width: 100%;
    font-family: monospace;
    font-size: 1.5rem;
    background-color: #d2d2d9 !important;
    border: 2px solid #fff;
  }
  #section1 {
    transform: rotate(0deg);
    .item {
      background-color: #d2d2d9;
      transform: rotate(0deg);
    }
  }
  #section2 {
    transform: rotate(180deg);
    .item {
      background-color: var(--result-color)!important;
      transform: rotate(180deg);
      animation: color-mix(var(--percent)) !important;
      -moz-animation: color-mix(var(--percent)) !important;
    }
  }
  #section3 {
    transform: rotate(180deg);
    .item {
      background-color: #d2d2d9;
      transform: rotate(180deg);
    }
  }
  #section4 {
    transform: rotate(180deg);
    .item {
      background-color: var(--result-color)!important;
      transform: rotate(180deg);
      animation: color-mix(var(--percent)) !important;
      -moz-animation: color-mix(var(--percent)) !important;
    }
  }
  #section5 {
    transform: rotate(180deg);
    .item {
      background-color: var(--result-color)!important;
      transform: rotate(180deg);
      animation: color-mix(var(--percent)) !important;
      -moz-animation: color-mix(var(--percent)) !important;
    }
  }
  .range-slider {
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: center;
      input[type="range"] {
          --thumbSize: 16px;
          --trackSize: 4px;
          --thumbBg: #356CC4;
          --trackBg: #DEDEDE;
          --progressBg: #356CC4;
        
          /* webkit progress workaround */
          --webkitProgressPercent: 0%;
        }
        
        input[type="range"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          height: var(--thumbSize);
          width: 100%;
          margin: 0;
          padding: 0;
        }
        input[type="range"]:focus {
          outline: none;
        }
        
        /* Thumb */
        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: var(--thumbSize);
          height: var(--thumbSize);
          background-color: var(--thumbBg);
          border-radius: calc(var(--thumbSize) / 2);
          border: none;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
          cursor: pointer;
        }
        input[type="range"]::-moz-range-thumb {
          -moz-appearance: none;
          appearance: none;
          width: var(--thumbSize);
          height: var(--thumbSize);
          background-color: var(--thumbBg);
          border-radius: calc(var(--thumbSize) / 2);
          border: none;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
          cursor: pointer;
        }
        input[type="range"]::-ms-thumb {
          -ms-appearance: none;
          appearance: none;
          width: var(--thumbSize);
          height: var(--thumbSize);
          background-color: var(--thumbBg);
          border-radius: calc(var(--thumbSize) / 2);
          border: none;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
          cursor: pointer;
        }
        
        /* Track */
        input[type="range"]::-webkit-slider-runnable-track {
          height: var(--trackSize);
          background-image: linear-gradient(
            90deg,
            var(--progressBg) var(--webkitProgressPercent),
            var(--trackBg) var(--webkitProgressPercent)
          );
          border-radius: calc(var(--trackSize) / 2);
        }
        input[type="range"]::-moz-range-track {
          height: var(--trackSize);
          background-color: var(--trackBg);
          border-radius: calc(var(--trackSize) / 2);
        }
        input[type="range"]::-ms-track {
          height: var(--trackSize);
          background-color: var(--trackBg);
          border-radius: calc(var(--trackSize) / 2);
        }
        
        /* Progress */
        input[type="range"]::-moz-range-progress {
          height: var(--trackSize);
          background-color: var(--progressBg);
          border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
        }
        input[type="range"]::-ms-fill-lower {
          height: var(--trackSize);
          background-color: var(--progressBg);
          border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
        }
  }
  .range-slider__thumb {
    text-align: center;
    color: #000;
    font-size: 16px;
    pointer-events: none;
    padding-left: 5px;
    padding-right: 5px;
  }
  .range-slider__bar {
    left: 16px;
    bottom: -200px;
    position: absolute;
    pointer-events: none;
    width: 8px;
    border-radius: 10px;
  }

//new code


}

