    /* Headings */
    
    .font-lexend {
        font-family: 'Lexend' !important;
    }
    
    .font-nunito {
        font-family: 'Nunito Sans' !important;
    }
    
    h1 {
        font-weight: 600;
        font-size: 30px;
        font-family: 'Lexend';
    }
    
    h2 {
        font-size: 24px;
        font-family: 'Lexend';
        &.light {
            font-weight: 300;
        }
        &.semibold {
            font-weight: 600;
        }
    }
    
    h3 {
        font-family: 'Lexend';
        font-weight: normal;
        font-size: 18px;
    }
    
    h4 {
        font-family: 'Lexend';
        font-weight: normal;
        font-size: 16px;
    }
    
    h5 {
        font-family: 'Lexend';
        font-weight: normal;
        font-size: 14px;
    }
    
    h6 {
        font-family: 'Lexend';
        font-weight: normal;
        font-size: 13px;
    }
    /* Number style */
    
    .num1 {
        font-weight: 800;
        font-size: 42px;
    }
    
    .num2 {
        font-weight: 800;
        font-size: 24px;
    }
    
    .num3 {
        font-weight: 800;
        font-size: 22px;
    }
    
    .num4 {
        font-weight: 800;
        font-size: 18px;
    }
    
    .num5 {
        font-weight: 800;
        font-size: 16px;
    }
    /* sub title style */
    
    .subtitle {
        font-weight: normal;
        font-size: 14px;
    }
    
    .subtitle-small {
        font-weight: normal;
        font-size: 12px;
    }
    
    .subtitle-extra-small {
        font-weight: normal;
        font-size: 10px;
    }
    /* body text style */
    
    .body-text {
        font-weight: normal;
        font-size: 11px;
    }
    
    .body-text-small {
        font-weight: normal;
        font-size: 10px;
    }
    
    // badge
    .badge {
        height: 19px;
        width: 19px;
        font-size: 14px;
        background: $gradient-red;
        color: $color-white;
        border-radius: 50%;
        font-weight: bold;
        font-family: 'Nunito Sans' !important;
        &.red {
            background: $gradient-red;
        }
        &.green {
            background: $gradient-green;
        }
    }
    
    // text ellipse
    .ellipse-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 0px);
    }
    
    // cursor pointer
    .cp {
        cursor: pointer;
    }

    // text color
    .lapse{
        color:$color-red1 !important;
    }
    .indicate{
        color:$color-secondary !important;
    }
    
    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        .col-xs-12 {
            width: 100%;
        }
        .col-xs-11 {
            width: 91.66%;
        }
        .col-xs-10 {
            width: 83.33%
        }
        .col-xs-9 {
            width: 75%;
        }
        .col-xs-8 {
            width: 66.66%;
        }
        .col-xs-7 {
            width: 58.33%;
        }
        .col-xs-6 {
            width: 50%;
        }
        .col-xs-5 {
            width: 50%;
        }
        .col-xs-4 {
            width: 33.33%;
        }
        .col-xs-3 {
            width: 25%;
        }
        .col-xs-2 {
            width: 16.66%;
        }
        .col-xs-1 {
            width: 8.33%;
        }
    }
    
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        .col-xs-12 {
            width: 100%;
        }
        .col-xs-11 {
            width: 91.66%;
        }
        .col-xs-10 {
            width: 83.33%
        }
        .col-xs-9 {
            width: 75%;
        }
        .col-xs-8 {
            width: 66.66%;
        }
        .col-xs-7 {
            width: 58.33%;
        }
        .col-xs-6 {
            width: 50%;
        }
        .col-xs-5 {
            width: 50%;
        }
        .col-xs-4 {
            width: 33.33%;
        }
        .col-xs-3 {
            width: 25%;
        }
        .col-xs-2 {
            width: 16.66%;
        }
        .col-xs-1 {
            width: 8.33%;
        }
    }