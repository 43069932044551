/* NPM Plugin Styles */
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/animate.css/animate.min";
@import "node_modules/hover.css/css/hover-min";
@import "node_modules/owl.carousel/dist/assets/owl.carousel";


/* Vendor Plugin Styles */
@import "vendor/bootstrap-datepicker.min";
@import "vendor/bootstrap-timepicker.min";

/* Abstract Styles */
@import "abstracts/variables";
@import "abstracts/mixins";

/* Base Styles */
@import "base/base";
@import "base/general";
@import "base/animations";

/* Layout Styles */
@import "layout/header";
@import "layout/footer";
@import "layout/grid";
@import "layout/navigation";

/* Component Styles */
@import "components/button";
@import "components/progress-bar";
@import "components/accordion";
@import "components/carousel";
@import "components/card";
@import "components/popover";
@import "components/modal";
@import "components/chart";
@import "components/form-input";
@import "components/tab";
@import "components/dropdown";
@import "components/side-menu";
@import "components/range-chart";
@import "components/apex-chart";

/* Page Styles */
@import "pages/customer-portal";
@import "pages/login";
@import "pages/overview";
@import "pages/timeline";
@import "pages/mypolicies";
@import "pages/my-policy";
@import "pages/pay-premium";
@import "pages/statement";
@import "pages/profile";
@import "pages/download";
@import "pages/nominee";
@import "pages/mystatement";
@import "pages/payment";
@import "pages/riderdetails";
@import "pages/search";
@import "pages/retirement";
@import "pages/trackapp";
@import "pages/postcovid";
