.form-group {
  text-align: left;
  margin-bottom: 2px;
  height: 68px;
  position: relative;
  &.dont-know-password {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      height: 76px;
      .help-text {
        top: 52px;
      }
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
      height: 76px;
      .help-text {
        top: 52px;
      }
    }
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      height: 76px;
      .help-text {
        top: 52px;
      }
    }
    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      height: 76px;
      .help-text {
        top: 52px;
      }
    }
  }
  label {
    bottom: 36px;
    left: 12px;
    position: relative;
    background-color: white;
    padding: 0;
    transition: 0.2s;
    pointer-events: none;
    letter-spacing: -0.16px;
    color: #a6a6b3;
    text-transform: capitalize;
    font-size: 16px;
    padding: 0px 4px;
  }
  .form-control {
    height: 48px !important;
    background: $color-white !important;
    border: 1px solid #c2e2f2 !important;
    border-radius: 10px !important;
    &:focus,
    &:hover {
      box-shadow: 6px 6px 30px #529aff29 !important;
      border: 1px solid #0c87c1 !important;
      border-radius: 10px !important;
    }
    &:disabled {
      border: 1px solid #c2e2f2 !important;
      cursor: not-allowed;
      box-shadow: none !important;
      background-color: $color-light-blue2 !important;
    }
  }
  .form-control:focus ~ label {
    bottom: 60px;
    font-size: 10px;
    text-transform: capitalize;
  }
  .form-control:valid ~ label {
    bottom: 60px;
    font-size: 10px;
    text-transform: capitalize;
  }
  .input-icon {
    position: absolute;
    right: 10px;
    top: 13px;
    cursor: pointer;
  }
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -13px;
  margin-left: 3px;
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #1dd900;
    border: #1dd900;
  }
  .custom-control-label {
    font-size: 10px;
    color: #7c7c8a;
    a {
      color: #0a4ca2;
    }
    &::before,
    &::after {
      top: -1px;
    }
  }
}

//custom radio
.radio {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;
  & + .radio {
    margin-top: 12px;
  }
  input {
    display: none;
    & + span {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        border: 1px solid #a6a6b3 !important;
        background: #fff;
      }
      &:after {
        content: "";
        display: block;
        width: 25px;
        height: 25px;
        background-image: url(./../assets/images/radio.svg);
        position: absolute;
        border-radius: 50%;
        border-color: #fff !important;
        top: -2px;
        left: -2px;
        opacity: 0;
        transform: scale(0, 0);
        transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }
    &:checked + span:after {
      opacity: 1;
      transform: scale(1, 1);
      border-color: #fff !important;
    }
  }
}

.otp-imput {
  width: 40px;
  background: $color-white !important;
  border: 1px solid #c2e2f2 !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  &:focus,
  &:hover {
    box-shadow: 6px 6px 30px #529aff29 !important;
    border: 1px solid #0c87c1 !important;
    border-radius: 10px !important;
  }
}

//select
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  height: 48px !important;
  background: $color-white !important;
  border: 1px solid $color-border !important;
  border-radius: 10px !important;
  &:focus,
  &:hover {
    box-shadow: 6px 6px 30px #529aff29 !important;
    border: 1px solid #0c87c1 !important;
    border-radius: 10px !important;
  }
  &:disabled {
    border: 1px solid $color-border !important;
    cursor: not-allowed;
    box-shadow: none !important;
  }
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
  background-color: transparent;
}

.select-selected {
  color: $color-light-grey;
  border-bottom: 0 !important;
  font-size: 16px !important;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: url(./../assets/images/arrow.svg);
  transform: rotate(180deg);
  top: 40px;
  right: 4px;
  width: 0;
  height: 0;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  top: 5px;
  right: 40px;
  content: url(./../assets/images/arrow.svg);
  transform: rotate(0deg);
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: $color-light-grey;
  padding: 5px 0px;
  border: 1px solid transparent;
  border-color: transparent transparent $color-border transparent;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:last-child {
    border-bottom: transparent;
  }
}
.select-items div {
  padding: 15px 0 !important;
}
/*style items (options):*/
.select-items {
  position: absolute;
  background: $color-white !important;
  border: 1px solid $color-border !important;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 10px;
  border-radius: 10px;
  margin-top: 5px;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  color: $color-primary;
  text-overflow: clip;
  white-space: inherit;
}

//custom radio with white card

.whitecard-radio {
  label {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    font-size: 16px;
    padding: 0px 8px 10px 3px;
    margin-bottom: 0px !important;
    @include for-phone-only {
      padding: 0px 0px 0px 0px;
      width: 100%;
      display: block;
      float: left;
    }
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        border: 1px solid $color-border;
        border-radius: 10px;
        padding: 10px;
        color: $color-grey3;
        background-color: $color-white;
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
          margin-bottom: 13px !important;
          margin-top: 5px;
          margin-left: 4px;
        }
        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
          margin-bottom: 13px !important;
          margin-top: 5px;
          margin-left: 4px;
        }
        &:before {
          display: flex;
          flex-shrink: 0;
          content: "";
          background-image: url(./../assets/images/radio-on-button.svg);
          width: 22px;
          height: 22px;
          margin-right: 5px;
          border: 0px solid #a6a6b3 !important;
          color: $color-grey3;
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      border: 1px solid $color-border;
      box-shadow: $card-primary-bs1;
      border-radius: 10px;
      padding: 10px;
      color: $color-light-grey;
      font-size: 16px;
      background-color: $color-white;
      img{
        float: right;
        right: 0;
      }
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        margin-bottom: 13px !important;
        margin-top: 5px;
        margin-left: 4px;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        margin-bottom: 13px !important;
        margin-top: 5px;
        margin-left: 4px;
      }
      &:hover {
        border: 1px solid $color-border1;
        box-shadow: $card-primary-bs;
        border-radius: 10px;
        padding: 10px;
        color: $color-grey3;
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-image: url(./../assets/images/radio-circle.svg);
        border-radius: 50%;
        margin-right: 5px;
        width: 22px;
        height: 22px;
      }
    }
  }
}

//number Field

.number-input {
  display: flex;
  align-items: center;
  height: 48px;
  background: #ffffff;
  border: 1px solid #c2e2f2;
  border-radius: 10px;
  padding: 0.375rem 0.75rem;
  .span-code {
    color: #a6a6b3;
  }
  .form-control {
    height: auto !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding: 0px 5px;
    &:hover {
      border: 0px !important;
      box-shadow: none !important;
      border-radius: 0px !important;
    }
    &:focus {
      border: 0px !important;
      box-shadow: none !important;
      border-radius: 0px !important;
    }
  }
  &:hover {
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #0c87c1 !important;
  }
  // &.code-hide {
  //   .span-code {
  //     display: none;
  //   }
  // }
  &.focused {
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #0c87c1 !important;
    // .span-code {
    //   display: block;
    // }
  }
}

//decrease increase input
.decreaseincrease{
  form {
    width:100%;
    text-align: center;
    padding-top: 36px;
  }
  
  .value-button {
    display: inline-block;
    border: 1px solid $color-border;
    background-color: $color-white;
    height: 40px;
    width:100%;
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .value-button:hover {
    cursor: pointer;
  }
  
  form #decrease {
    color: #44C8F5;
    font-size: 18px;
    line-height: 36px;
  }
  
  form #increase {
    color: #44C8F5;
    font-size: 18px;
    line-height: 36px;
  }
  
  form #input-wrap {
    margin: 0px;
    padding: 0px;
  }
  
  input#number {
    text-align: center;
    border:1px solid $color-border1;
    border-radius: 10px;
    margin: 0px;
    width:100%;
    height: 40px;
    box-shadow: 0px 6px 10px #d5e2f2;
    color: #000000BC;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  .customp-5form{
    padding: 5px;
  }
}

.form-group {
  .form-control:disabled ~ label {
    background-color: transparent;
  }
}