// circular progress bar stylings
.circular-bar {
  position: relative;
  display: inline-flex;
  align-items: center;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    padding-left: 14px;
  }
  @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
    padding-left: 14px;
  }
  .circular-chart {
    height: 130px;
    width: 130px;
    fill: transparent;
    stroke-linecap: round;
    .disabled-circle {
      stroke-width: 8px;
      stroke: $color-blue;
    }
    .circle {
      stroke-width: 8px;
      stroke: $color-green1;
      transform-origin: center;
      @include animation(circle-animate 3s reverse);
    }
    @keyframes circle-animate {
      to {
        stroke-dasharray: 0 100;
      }
    }
  }
  .chart-label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 130px;
    height: 130px;
    top: 0;
    position: absolute;
    .value-number {
      position: relative;
      color: $color-white;
      width: 100%;
      font-size: 21px;
      font-weight: 800;
      line-height: 1;
      letter-spacing: 1.3px;
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        font-size: 18px;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        font-size: 18px;
      }
    }
    .insured-text {
      font-size: 11px;
      font-weight: 600;
      line-height: 1.15;
      letter-spacing: -0.39px;
      text-align: center;
      color: #f7fcff;
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        font-size: 12px;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        font-size: 12px;
      }
    }
  }
}

.hprogress {
  margin: 0 auto;
  width: 130px;
  @include for-phone-only {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }
  .hprogressline {
    border-top: 1px solid $color-grey4;
    width: 50px;
    z-index: 1;
    margin-top: -5px;
  }
  .hprogressline-active {
    border-top: 1px solid $color-blue1;
    width: 50px;
    z-index: 1;
    margin-top: -5px;
  }
  .hicon {
    width: 10px;
    height: 10px;
    background-color: $color-grey4;
    border-radius: 5px;
    display: inline-block;
    margin-top: -10px;
    z-index: 2;
  }
  .hicon-activ {
    width: 10px;
    height: 10px;
    background-color: $color-blue1;
    border-radius: 5px;
    display: inline-block;
    margin-top: -10px;
    z-index: 2;
  }
}

.hprogress-small {
  margin: 0 auto;
  width: 100px;
  padding: 10px 0px 30px 0px;
  @include for-phone-only {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }
  .hprogressline {
    border-top: 1px solid $color-grey4;
    width: 80px;
    z-index: 1;
    margin-top: -5px;
  }
  .hprogressline-active {
    border-top: 1px solid $color-blue1;
    width: 80px;
    z-index: 1;
    margin-top: -5px;
  }
  .hicon {
    width: 10px;
    height: 10px;
    background-color: $color-grey4;
    border-radius: 5px;
    display: inline-block;
    margin-top: -10px;
    z-index: 2;
  }
  .hicon-activ {
    width: 10px;
    height: 10px;
    background-color: $color-blue1;
    border-radius: 5px;
    display: inline-block;
    margin-top: -10px;
    z-index: 2;
  }
}

// Progress Pointers starts

.progress-pointers {
  display: flex;
  justify-content: space-between;
  width: max-content;
}

@media (max-width: 575px) {
  .progress-pointers {
    margin-top: 0px;
  }
}

// Progress Pointers Ends

// horizental progress bar

.hdotprogress-bar {
  input[type="range"] {
    --thumbSize: 16px;
    --trackSize: 4px;
    --thumbBg: #356CC4;
    --trackBg: #DEDEDE;
    --progressBg: #356CC4;
  
    /* webkit progress workaround */
    --webkitProgressPercent: 0%;
  }
  
  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: var(--thumbSize);
    width: 100%;
    margin: 0;
    padding: 0;
  }
  input[type="range"]:focus {
    outline: none;
  }
  
  /* Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  input[type="range"]::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  
  /* Track */
  input[type="range"]::-webkit-slider-runnable-track {
    height: var(--trackSize);
    background-image: linear-gradient(
      90deg,
      var(--progressBg) var(--webkitProgressPercent),
      var(--trackBg) var(--webkitProgressPercent)
    );
    border-radius: calc(var(--trackSize) / 2);
  }
  input[type="range"]::-moz-range-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }
  input[type="range"]::-ms-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }
  
  /* Progress */
  input[type="range"]::-moz-range-progress {
    height: var(--trackSize);
    background-color: var(--progressBg);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }
  input[type="range"]::-ms-fill-lower {
    height: var(--trackSize);
    background-color: var(--progressBg);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }
.rangeValue{
  text-align: center;
  font-size: 16px;
  color: $color-grey3;
  font-weight: 800;
}
}

.vprogress{
  display: flex;
  justify-content: end;
  align-items: flex-end;
  padding: 0px 5px;
  .vprogressfill-one{
    background-color:$color-light-red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 45px;
    width: 100%;
  }
  .vprogressfill-two{
    background-color:$color-light-red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 55px;
    width: 100%;
  }
  .vprogressfill-three{
    background-color:$color-light-red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 70px;
    width: 100%;
  }
  .vprogressfill-four{
    background-color:$color-light-red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 85px;
    width: 100%;
  }
  .vprogressfill-five{
    background-color:$color-light-red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100px;
    width: 100%;
  }
  small{
    text-align: center;
    font-size: 12px;
    padding-top: 15px;
    color: $color-grey;
    width: 100%;
    position: absolute;
    margin-top:90px;
    padding-bottom: 15px;
    top: 0;
    padding-left: 10px;
  }
}
