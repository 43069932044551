.postcovid-section {
  .page-section {
    h2.light {
      font-size: 36px;
      padding-bottom: 0px !important;
    }
    .slogun{
        font-size: 14px;
        color: $color-grey2;
        padding-bottom: 10px;
    }
  }
  .card-white{
    padding: 30px;
    height: 100%;
    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      height:auto;
      margin-top: 10px;
      margin-bottom: 10px;
      }
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      height:auto;
      margin-top: 10px;
      margin-bottom: 10px;
      }
    .valueresult{
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
    #pcscshoppingMallsValue, #pcscworkfromHomeValue, #pcscweekendGetawaysValue, #pcsctravelholidayPlansValue, #pcscotherluxuryItemsValue{
      margin: 0 5px;
    }
    .ageindicationtext{
      font-size: 12px;
      color: rgba($color: $color-black, $alpha: 0.6);
      padding: 0px 15px;
    }
  }
  .img-title{
      font-size: 18px;
      font-family: 'Lexend' !important;
      letter-spacing: -0.60px;
      img{
          width: 60px;
          height: 60px;
          margin-right: 16px;
      }
  }

  .postcovid-card{
      display: flex;
      justify-content: center;
    .card-white{
        padding:15px;
        box-shadow: none;
        text-align: center;
        font-size: 16px;
        align-items: center;
        font-weight: 600;
        color: $color-light-black;
        margin: 5px;
        width: 188px;
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 100% !important;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            width: 100% !important;
        }
        img{
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
        }
        }

  }
  .malemargin{
    margin-left: 92px;
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      margin-left: 132px;
  }
  @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      margin-left: 132px;
  }
  }
  .femalemargin{
    margin-left: 75px;
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      margin-left: 115px;
  }
  @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      margin-left: 115px;
  }
  }


}

div#chart2 {
  height: 200px!important;
}

div#chart3 {
  height: 200px!important;
}

div#chart4 {
  height: 200px!important;
}

div#chart5 {
  height: 200px!important;
}

div#chart6 {
  height: 200px!important;
}