// owl carousel scss
.carousel-wrapper {
    .owl-nav {
        position: absolute;
        top: -36px;
        right: 0px;
    }
    .card {
        padding: 20px 30px 30px;
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding: 20px 16px 20px;
        }
        display: block;
        &.yellow {
            background: $gradient-yellow;
            box-shadow: 2px 2px 10px #b0974599;
            border-radius: 10px;
        }
        &.red {
            background: $gradient-dark-red;
            box-shadow: 2px 2px 10px #b0546299;
            border-radius: 10px;
        }
    }
    .owl-stage-outer {
        padding: 10px 0px;
    }
    .nav-button {
        width: 24px;
        height: 30px;
        &.owl-prev {
            &:hover {
                .carousel-icon {
                    background: #E3EEFC;
                    border-top-left-radius: 50px;
                    border-bottom-left-radius: 50px;
                }
            }
        }
        &.owl-next {
            margin-left: 10px;
            &:hover {
                .carousel-icon {
                    background: #E3EEFC;
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                }
            }
        }
    }
}

.login-carousel {
    width: 780px;
    .carousel-indicators {
        position: absolute;
        right: 0;
        top: 565px;
        left: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-right: unset !important;
        margin-left: unset !important;
        list-style: none;
        width: 100%;
        li {
            width: 5px;
            height: 5px;
            background-color: #fff;
            border-radius: 50%;
            background-clip: none !important;
            border-top: none !important;
            border-bottom: none !important;
            &.active {
                width: 29px;
                height: 5px;
                background-color: #fff;
                border-radius: 50px !important;
            }
        }
    }
    .carousel-item {
        &.active {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}