.my-statement-section {
  .card {
    &.card-blue {
      padding: 20px;

      .policy-number-heading,
      .policy-date-heading {
        font-size: 12px;
        color: $color-lighter-grey;
        margin: 0 0 5px;
        @include for-phone-only {
          font-size: 10px;
        }
      }

      .policy-number,
      .policy-date {
        color: $color-white;
        font-size: 18px;
        font-weight: 800;

        @include for-phone-only {
          font-size: 15px;
        }
      }

      .insurance-name {
        font-size: 18px;
        margin: 0 0 5px;
        color: #fff;
      }

      .plan-name {
        font-size: 14px;
        color: $color-lighter-grey;
      }

      .titleborder {
        border-right: 1px solid $color-light-blue6;

        @include for-phone-only {
          border-right: 0px;
          border-bottom: 1px solid $color-light-blue6;
          padding-bottom: 20px;
        }
      }
    }
  }

  select {
    border: 1px solid $color-border;
    padding: 12px;
    border-radius: 10px;
    color: $color-light-grey;

    @include for-phone-only {
      width: 100%;
      margin-top: 20px;
    }
  }

  .page-section {
    // padding-right: 0;
    h2.light {
      font-size: 36px;
    }
  }
}

.my-statement {
  .policy-statement-tabs {
    padding-left: 0px;
  }

  .nav-pills-custom .nav-link {
    background: #f9fcff;
    border-bottom: 1px solid #b9ddf0;
    border-radius: 0px;
    color: #4d4d5c;
    text-align: left;
    font-size: 16px;

    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-top: 0.25rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0.5rem;
    align-items: center;
  }
  .arrow-image {
    display: inline-block;
    background: url(../assets/images/right-tab-white.svg);
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .nav-pills-custom .nav-link.active {
    background: #f9fcff;
    color: $color-primary !important;
    font-weight: bold;
    .arrow-image {
      background: url(../assets/images/right-tab-arrow.svg);
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
    }
  }

  .card-policies-statement {
    display: flex;
    justify-content: center;
    align-items: center;

    .images-steps-policy-stetement {
      max-width: 60%;
    }
    .update-alerts-header {
      // text-align: left;
      font-size: 14px;
      font-weight: $font-weight-regular;
      font-family: "Nunito Sans", sans-serif;
      color: $color-grey2;
    }
  }

  .card-updates-alerts {
    padding: 20px 30px 20px 30px;
    align-items: normal;
  }
}

.btn-toggle {
  top: 50%;
  transform: translateY(-50%);
}
.btn-toggle {
  // margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #e3f1f9;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle:before {
  content: "Off";
  left: -4rem;
}
.btn-toggle:after {
  content: "On";
  right: -4rem;
  opacity: 0.5;
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.active:before {
  opacity: 0.5;
}
.btn-toggle.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  display: none;
}
.btn-toggle:before,
.btn-toggle:after {
  color: #073aa3;
}
.btn-toggle.active {
  background: #99cee5;
}
.btn-toggle.btn-lg {
  position: relative;
  border: none;
  width: 2.2rem;
  height: 1.1rem;
  left: 30px;
  top: 4px;
  border-radius: 2.5rem;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active {
  outline: none;
}
.btn-toggle.btn-lg:before,
.btn-toggle.btn-lg:after {
  width: 5rem;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  top: -2px;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle.btn-lg:before {
  content: "Off";
}
.btn-toggle.btn-lg:after {
  content: "On";
  opacity: 0.5;
}
.btn-toggle.btn-lg > .handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 1.875rem;
  background: #064aa2;
  transition: left 0.25s;
}
.btn-toggle.btn-lg.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-lg.active > .handle {
  left: 1.1rem;
  transition: left 0.25s;
}
.btn-toggle.btn-lg.active:before {
  opacity: 0.5;
}
.btn-toggle.btn-lg.active:after {
  opacity: 1;
}

/* Add indicator arrow for the active tab */
@media (max-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: "";
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
  .mobile-tabs-my-statement {
    display: none;
  }

  .policy-statement-tabs {
    .card-policies-statement {
      margin-top: 16px;
      // padding: 20px 20px 20px 20px;
    }
    // .btn-toggle.btn-lg {
    //   left: 80px;
    //   top: 10px;
    // }
    .card-updates-alerts {
      padding: 20px 20px 20px 20px;
    }
  }
  .nav-pills-custom {
    margin-top: 20px;
  }
  .update-alerts-header {
    // text-align: center;
  }
}

// @media (max-width: 1366px) {
//   .btn {
//     font-size: 11px !important;
//   }
// }

.policy-statement-spacing {
  padding-right: 0px;
}

.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

// Policy Services Stylings by Salman Starts

.padding-adjustment-row {
  margin-right: -8px;
  margin-left: -8px;
  .padding-column {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.accordion-row {
  margin-right: -8px;
  margin-left: -8px;
  .accordion-column {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 1366px) {
  .my-statement-section {
    .page-section {
      padding-right: 0px;
    }
  }
}

.policy-services-lg {
  margin-top: 20px;
}

.accordion-mobile {
  display: none;
  .accordion-main {
    .accordion-header {
      .btn {
        display: block;
        width: 100%;
        padding: 0px;
        &[aria-expanded="true"] {
          .arrow-image {
            background: url(../assets/images/right-tab-arrow.svg);
            transition-duration: 0.2s;
            transition-delay: 0s;
            transform: rotate(90deg);
          }
          .heading-line {
            font-weight: bold;
            color: #074aa3;
          }
        }
      }
      .heading-toggle {
        font-family: "Nunito Sans", sans-serif;
        border-bottom: 1px solid #b9ddf0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 7px;
        .heading-line {
          font-size: 16px;
          color: #4d4d5c;
        }
      }
    }
    .accordion-body {
      padding-top: 10px;
    }
  }
}

.rtoggle-sec {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  .rtoggle-heading {
    width: calc(100% - 145px);
    font-size: 14px;
    color: #7d7d8c;
    h5 {
      margin-bottom: 0px;
      font-family: "Nunito Sans", sans-serif;
    }
  }
  .rtoggle-div {
    width: 145px;
    padding-top: 4px;
    padding-left: 45px;
  }
}

@media (max-width: 575px) {
  .accordion-row {
    flex-direction: column;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .rtoggle-sec {
    flex-direction: column;
    .rtoggle-heading {
      width: 100%;
      margin-bottom: 10px;
    }
    .rtoggle-div {
      width: 100%;
      padding-left: 10px;
    }
  }
}

@media (max-width: 767px) {
  .policy-services-lg {
    display: none;
  }
  .accordion-mobile {
    display: block;
  }
  .update-alerts-header {
    text-align: left !important;
  }
  .btn-toggle.btn-lg,
  .btn-group-lg > .btn-toggle.btn {
    left: 32px;
  }
  .policy-statement-spacing {
    padding-right: 15px;
  }
  // .my-statement {
  //   .card-updates-alerts{
  //     padding: 10px;
  //   }
  // }
}

.btn-toggle-div {
  text-align: center;
  .btn {
    left: 0px;
  }
}

// Policy Services Stylings by Salman Ends

// Policy Loan Application Page Styling Starts

.section-details {
  .content-sec {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  padding-top: 10px;
  .form-group {
    height: auto;
    margin-bottom: 20px;
  }
  .sec-header {
    font-size: 11px;
    color: #7c7c8a;
    margin-bottom: 1px;
  }
  .moredetails-content {
    font-size: 16px;
    font-weight: 700;
    color: #51515c;
  }
}

.modal-policy-loans {
  .table-future-pre {
    max-height: 325px;
  }
}

.bottom-sec {
  .btn.btn-tertiary {
    min-width: unset;
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    .img-btn {
      margin-left: 3px;
      margin-bottom: 2px;
    }
  }
  .buttons-div {
    display: flex;
    column-gap: 10px;
    justify-content: center;
  }
}

.warning-txt {
  font-size: 10px;
  color: #707070;
  display: flex;
  align-items: center;
  .txt-blue {
    color: #003399;
  }
}

.break-line {
  border-color: #c2e2f2;
}

.option-selected {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 21px;
  margin-bottom: 20px;
  a {
    color: #7c7c8a;
    font-size: 14px;
  }
  .completetext {
    font-size: 14px;
    align-items: center;
    display: flex;
    color: #00b200;
    img {
      margin-right: 12px;
    }
  }
}

.f-semi {
  font-weight: 600;
}

.radio-label {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 0px;
  @include for-phone-only {
    padding: 0px 0px 0px 0px;
    width: 100%;
    display: block;
    float: left;
  }
  input {
    position: absolute;
    opacity: 0;
    &:checked + span {
      border: 1px solid $color-border;
      border-radius: 10px;
      padding: 10px;
      color: $color-grey3;
      background-color: $color-white;
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        margin-bottom: 5px;
        margin-top: 5px;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        margin-bottom: 5px;
        margin-top: 5px;
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-image: url(./../assets/images/radio-on-button.svg);
        width: 22px;
        height: 22px;
        margin-right: 5px;
        border: 0px solid #a6a6b3;
        color: $color-grey3;
      }
    }
  }
  span {
    height: 48px;
    display: flex;
    align-items: center;
    border: 1px solid $color-border;
    border-radius: 10px;
    padding: 10px;
    color: $color-light-grey;
    font-size: 16px;
    background-color: $color-white;
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      margin-bottom: 5px;
      margin-top: 5px;
    }
    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      margin-bottom: 13px;
      margin-top: 5px;
    }
    &:hover {
      border: 1px solid $color-border1;
      border-radius: 10px;
      padding: 10px;
      color: $color-grey3;
    }
    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-image: url(./../assets/images/radio-circle.svg);
      border-radius: 50%;
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
  }
}

.declaration-form {
  margin-top: 10px;
  .form-group {
    height: auto;
    margin-bottom: 20px;
  }
}

.form-sec-div {
  .form-control {
    &::placeholder {
      color: #a6a6b3;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
}

.upload-img-box {
  margin-bottom: 20px;
  label {
    background-color: #e5eefb;
    border: 1px dashed #c1dcee;
    height: 48px;
    font-size: 12px;
    font-family: "Lexend";
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    border-radius: 10px;
    padding: 16px;
    color: #074aa3;
    .txt-grey {
      color: #51515c;
      opacity: 0.7;
      line-height: 1;
      margin-bottom: 0px;
      font-weight: $font-weight-regular;
    }
    .upload-txt {
      display: flex;
      text-align: center;
    }
  }
}

.uploaded-img-box {
  margin-bottom: 20px;
  background-color: #e5eefb;
  border: 1px solid #c1dcee;
  height: 48px;
  font-size: 12px;
  font-family: "Lexend";
  font-weight: 600;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  border-radius: 10px;
  padding: 16px;
  color: #074aa3;
  .txt-file {
    font-weight: $font-weight-regular;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
    height: max-content;
    text-overflow: ellipsis;
  }
  .upload-txt {
    cursor: pointer;
    display: flex;
    text-align: center;
  }
}

.type-box {
  padding-top: 11px;
  resize: none;
}

.description-para {
  font-size: 12px;
  color: #7c7c8a;
  padding-bottom: 5px;
  .txt-blue {
    color: $color-primary;
  }
  .desc-heading {
    color: #0a4ca2;
    font-size: 10px;
    font-weight: bold;
  }
  .desc-list {
    padding-left: 12px;
    list-style-type: decimal;
  }
}

.seperator-title {
  .or-block {
    display: flex;
    align-items: center;
    width: 100%;
    .or-text {
      font-size: 12px;
      color: #7d7d8c;
      white-space: nowrap;
      font-weight: 700;
      padding: 0 10px;
    }
    .hr-line {
      background: #c2e2f2;
      height: 1px;
      width: 100%;
      margin: 15px 0px;
    }
  }
}

@media (max-width: 575px) {
  .section-details {
    .content-sec {
      .sec-header {
        font-size: 10px;
      }
      .moredetails-content {
        font-size: 14px;
      }
    }
  }
  .bottom-sec {
    .buttons-div {
      .btn.btn-primary,
      .btn.btn-secondary {
        min-width: unset !important;
        width: calc(50% - 10px);
      }
    }
  }
}

@media (max-width: 767px) {
  .type-box {
    min-height: 120px;
  }
  .option-selected {
    height: auto;
    margin-top: 0px;
  }
  .bottom-sec {
    .btn.btn-tertiary {
      margin: auto;
    }
  }
  .change-row-align {
    flex-direction: column-reverse;
  }
  .save-draft-div {
    padding-top: 20px;
  }
}

// Policy Loan Application Page Styling Ends

// Loan Repayment Page Styling Starts

.total-amnt {
  font-size: 18px;
}

// Loan Repayment Page Styling Ends

.uploded-list {
  display: block;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ul {
    display: block;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  li {
    font-weight: $font-weight-regular;
    display: inline-block;
    width: max-content;
    list-style-type: none;
    &::after {
      content: ",\00a0";
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.upload-span {
  display: block;
  width: max-content;
}
