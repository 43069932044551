.sidebar-menu {
  height: 100%;
  width: 0;
  position: fixed;
  // z-index: 21;
  z-index: 1000;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #0c87c1, #0a4ca2);
  overflow: hidden;
  transition: 0.4s;
  .hr-line {
    height: 1px;
    background: #0b86c0;
    margin: 15px 15px 15px 20px !important;
  }
  .search-box {
    padding: 10px 20px;
    width: 100%;
    .search-icon {
      position: absolute;
      left: 34px;
      top: 19px;
    }
    .search-input {
      height: 40px;
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      padding-left: 40px;
      font-size: 18px;
      width: 100%;
      color: $color-white;
      &:focus,
      &:focus-visible {
        background: transparent;
        border: none;
        border-bottom: 1px solid white;
        outline: none !important;
      }
    }
  }
  .sidebar-body {
    min-width: 300px;
    padding: 0px 20px 20px 20px;
    height: calc(100% - 116px);
    overflow-y: auto;
  }
  a {
    padding: 15px 16px;
    text-decoration: none;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    font-family: "Lexend" !important;
    font-size: 18px;
    letter-spacing: -0.18px;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 5px 0px;
    border-radius: 10px;
    &.bottom-text {
      color: #9dbedd;
    }
    &:hover {
      border-radius: 10px;
      background-color: #51a3d0;
      color: $color-white;
      font-weight: 600;
    }
  }
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
  }
  .navbar {
    display: block;
    .sub-nav {
      .dropdown-icon {
        // transition-duration: 0.4s;
        // transition-delay: 0s;
        margin-left: auto;
      }
      &[aria-expanded="true"] {
        .dropdown-icon {
          transform: rotate(180deg);
        }
      }
    }
    .dropdown-menu {
      background-color: transparent;
      border: none;
      box-shadow: none;
      a {
        font-size: 16px;
        color: #9dbedd;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.search-input::-webkit-input-placeholder {
  color: #9dbedd;
}

.search-input:-moz-placeholder {
  color: #9dbedd;
}

.search-input::-moz-placeholder {
  color: #9dbedd;
}

:-ms-input-placeholder {
  color: #9dbedd;
}

::-webkit-input-placeholder {
  color: #a6a6b3;
}

.search-input:-moz-placeholder {
  color: #a6a6b3;
}

::-moz-placeholder {
  color: #a6a6b3;
}

:-ms-input-placeholder {
  color: #a6a6b3;
}

#overlay {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
}

.header-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0px 20px;
  .logo-div {
    display: inline-flex;
    .logo {
      padding-left: 14.4px;
      height: 25px;
    }
  }
}

.list-content {
  a {
    font-size: 12px;
    margin: 5px 0px;
  }
}
