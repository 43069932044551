.search-field {
  position: relative;
  display: inline-block;
  width: 100%;
  .search-img {
    position: absolute;
    top: 19px;
    left: 16px;
    z-index: 1;
  }
  .input-search {
    padding-left: 42px;
  }
}

.result-shown {
  display: flex;
  align-items: center;
  .head-result {
    font-size: 20px;
    color: $color-light-grey;
    font-weight: $font-weight-light;
    margin-bottom: 0px;
  }
}

.entered-value-list {
  border-radius: 6px;
  .entered-value-each {
    font-size: 12px;
    display: inline-block;
    position: relative;
    background-color: $color-white;
    color: $color-light-grey;
    border: 1px solid $color-border;
    padding: 4px 30px 4px 10px;
    margin: 3px 2px;
    border-radius: 5px;
    .icon-remove {
      cursor: pointer;
      position: absolute;
      right: 10px;
      margin-right: 0px;
      font-size: 12px;
      color: #fff;
    }
  }
}

.result-list {
  > li {
    padding-top: 25px;
    border-top: 1px solid $color-border;
    padding-bottom: 14px;
  }
  .content-sec {
    font-size: 14px;
    color: $color-grey2;
    h2 {
      color: $color-light-black;
      font-weight: $font-weight-regular;
    }
  }
  .button-single {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
    .btn.btn-tertiary {
      min-width: unset;
      width: max-content;
    }
  }
}

@media (max-width: 767px) {
  .result-shown {
    flex-direction: column;
    align-items: start;
    .head-result {
      margin-bottom: 5px;
    }
  }
}

// Raise a claim page Starts

.raise-claim-page {
  .details-card {
    .card.card-white {
      .divider-two {
        border-right: 1px solid #b9ddf0;
      }
    }
  }
  .upload-box {
    label {
      justify-content: space-between;
      padding: 20px;
    }
    &.height-change {
      label {
        height: 77px;
        .g-txt {
          font-weight: $font-weight-regular;
          font-size: 16px;
          color: $color-light-grey;
        }
      }
    }
  }
  .uploaded-file-div {
    height: 77px;
  }
}

.sec-upload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sec-header {
    font-size: 14px;
    color: $color-light-black;
    font-weight: $font-weight-semibold;
  }
  .moredetails-content {
    font-size: 12px;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    color: $color-grey;
    font-weight: $font-weight-semibold;
  }
  .form-group {
    height: auto;
  }
  .custom-select {
    height: 36px !important;
    padding: 2px 12px;
  }
  .select-selected {
    font-size: 14px !important;
    &:after {
      top: 35px;
    }
    &.select-arrow-active {
      &:after {
        top: 0px;
      }
    }
  }
  .download-btn {
    font-weight: $font-weight-semibold;
    padding: 0px;
    width: max-content;
    color: $color-primary;
  }
}

.row-docs {
  .sec-upload {
    min-height: 208px;
    // max-height: 208px;
  }
  .upload-box {
    label {
      justify-content: center;
    }
  }
}

@media (max-width: 575px) {
  .sec-upload-top {
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .raise-claim-page {
    .details-card {
      .card-detail-two {
        padding: 0.5rem 1.25rem;
      }
      .content-sec {
        margin: 10px 0px;
      }
    }
  }
}

@media (max-width: 991px) {
  .raise-claim-page {
    .details-card {
      .card.card-white {
        .divider-two {
          border-right: none;
        }
      }
    }
  }
}
// Raise a claim page Ends
