
/* Font Weights */
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-exbold: 800;
$font-weight-black: 900;

/* Colors */
$color-primary: #074AA3 !default;
$color-secondary: #0086C1 !default;
$color-blue: #0A4CA2;
$color-blue1: #356CC4;
$color-light-blue1: #B9DDF0;
$color-light-blue2: #E3EEFC;
$color-light-blue3: #F2FBFF;
$color-light-blue4: #C1D6F2;
$color-light-blue5: #F9FCFF;
$color-light-blue6: #359ccc;
$color-white:#FFFFFF;
$color-green: #00D900;
$color-green1:#1DD900;
$color-green2:#00B200;
$color-dark-orange: #FF9D00;
$color-red:#FF2626;
$color-red1:#db3333;
$color-light-green:#B2EEC6;
$color-light-orange: #FEE0B2;
$color-light-red: #F8BCBC;
$color-black:#000000;
$color-black1:#141b2f;
$color-light-black: #4D4D5C;
$color-grey:#7C7C8A;
$color-grey1: #9A9AAD;
$color-grey2: #7D7D8C;
$color-grey3: #51515C;
$color-grey4: #DEDEDE;
$color-light-grey:#A6A6B3;
$color-lighter-grey:#D2D2D9;
$color-border: #C2E2F2;
$color-border1: #0C87C1;


/* Shadows */
$card-primary-bs: 0px 6px 10px #D5E2F2;
$card-primary-bs1: 0px 6px 10px #ffffff;
$card-secondary-bs: 0px 6px 10px #D5E2F2;
$btn-primary-bs: 0px 0px 6px #0B86C1;
$btn-secondary-bs: 0px 0px 5px #0B86C1;
$icon-bs: 6px 6px 20px #0A4CA24D;
$icon-hover-bs: 0px 0px 20px #0B86C1CC;


/* gradient */
$gradient-blue: transparent linear-gradient(180deg, #0B86C1 0%, #074AA3 100%) 0% 0% no-repeat padding-box;
$gradient-red:transparent linear-gradient(180deg, #FE2626 0%, #991717 100%) 0% 0% no-repeat padding-box;
$gradient-green: transparent linear-gradient(180deg, #1DD900 0%, #11802A 100%) 0% 0% no-repeat padding-box;
$gradient-orange:transparent linear-gradient(180deg, #FE9D00 0%, #A66600 100%) 0% 0% no-repeat padding-box;
$gradient-light-red:transparent linear-gradient(180deg, #EF4E64 0%, #A22C3C 100%) 0% 0% no-repeat padding-box;
$gradient-light-green:transparent linear-gradient(180deg, #50B58B 0%, #387064 100%) 0% 0% no-repeat padding-box;
$gradient-dark-yellow:transparent linear-gradient(180deg, #D2AA2C 0%, #A28017 100%) 0% 0% no-repeat padding-box;
$gradient-black:transparent linear-gradient(180deg, #7D7D8C 0%, #4D4D57 100%) 0% 0% no-repeat padding-box;
$gradient-white:transparent linear-gradient(180deg, #FFFFFF 0%, #E3EEFC 100%) 0% 0% no-repeat padding-box;
$gradient-yellow: transparent linear-gradient(180deg, #D8B956 0%, #B19745 100%) 0% 0% no-repeat padding-box;
$gradient-dark-red: transparent linear-gradient(180deg, #EF6F82 0%, #B15462 100%) 0% 0% no-repeat padding-box;