.retirement-section {
  .page-section {
    h2.light {
      font-size: 36px;
      padding-bottom: 0px !important;
    }
    .slogun{
        font-size: 14px;
        color: $color-grey2;
        padding-bottom: 10px;
    }
  }
  .card-white{
      padding: 30px;
      height: 100%;
      @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      height: auto;
      margin-top:10px;
      margin-bottom:10px;
      }
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      height: auto;
      margin-top:10px;
      margin-bottom:10px;
      }
      .ageindicationtext{
        font-size: 12px;
        color: rgba($color: $color-black, $alpha: 0.6);
        padding: 0px 15px;
        margin-top: 30px;
      }
      .valueresult{
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }
      #currentAgeValue{
        margin: 0px 5px;
      }
      #retirementAgeValue{
        margin: 0px 5px;
      }
      .investment{
        border-right: 1px solid #C2E2F2;
        @include for-phone-only {    
          border-right:none;
          margin-top: 30px;
        }
      }
      ul{
        margin-top: 35px;
        @include for-phone-only {    
          margin-top: 0px;
        }
        .mtforspe{
          @include for-phone-only {    
            padding-top: 35px;
          }
        }
        li{
          padding:15px 0px;
          margin: 0px;
          align-items: center;
          display: flex;
          .custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
            top:2px !important;
          } 
        }
        .custom-control-label{
          font-size: 16px;
          font-weight: 600;
          color:$color-black;
        }
      }
  }
  .img-title{
      font-size: 18px;
      font-family: 'Lexend' !important;
      letter-spacing: -0.54px;
      img{
          width: 60px;
          height: 60px;
          margin-right: 16px;
      }
  }
  .sec-header {
    font-size: 11px;
    color: $color-grey;
    width: 100%;
    display: block;
    @include for-phone-only {
      text-align: left;
      font-size: 10px;
    }
  }
  .span-code{
    width: 40px;
  }
  .total-sum1{
    font-size: 16px !important;
    color:$color-lighter-grey;
    font-family: "Nunito Sans", sans-serif;
  }
  .total-sum{
    font-size: 12px !important;
    padding-top: 0px !important;
    @include for-phone-only {    
      padding-left: 0px;
      padding-right: 0px;
      margin-left: -15px;
    }
  }
  .sum-section{
    border-right: 1px solid #2c8ec5;
    @include for-phone-only {    
      border-right:none;
      border-bottom: 1px solid #2c8ec5;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 10px;
    }
  }
  .cardnumber{
    color: #FFFFFF;
    font-weight: bold;
    font-size: 42px;
    text-align: right;
    @include for-phone-only {   
      font-size: 32px;
      text-align: left;
      margin-left: -15px;
    }
  }
  .cardnumber-blue{
    color: #FFFFFF;
    font-weight: bold;
    font-size: 42px;
    text-align:center;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      font-size: 32px;
      margin-top: 10px;
  }
  @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
      font-size: 32px;
      margin-top: 10px;
  }
    @include for-phone-only {   
      font-size: 32px;
      text-align:center;
      margin-left: -15px;
    }
  }
  .total-sum{
    font-size: 14px;
    color:$color-lighter-grey;
    font-weight: normal;
  }
}

@include for-phone-only { 
  .card-blue{
    padding: 30px 40px !important;
  }
}

.delay-result {
  font-size: 12px;
  color:$color-red1;
  font-weight: 800;
  letter-spacing: -0.18px;
  width: 100%;
  text-align: center;
  padding-bottom: 8px;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      display: none !important;
      &.ipad-p {
          display: block !important;
      }
  }
  @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
      display: none !important;
      &.ipad-p {
          display: block !important;
      }
  }
  @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      display: none !important;
      &.ipad-p {
          display: block !important;
      }
  }
  @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      display: none !important;
      &.ipad-p {
          display: block !important;
      }
  }
}

.vchart-heading{
  font-size: 16px;
  color: $color-grey3;
  padding-bottom: 20px;
  small{
    font-size: 10px;
  }
}

.redcircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  margin-top: 10px;
  right: 105px;
  &.red {
      background: linear-gradient(181deg, #EF4E64 99%, #A22C3C 0%);
  }
}

// Policy Services Stylings by Salman Ends

//half chart
.semi-donut{
  --percentage: 0;
  --fill:#FF3D00;
  width: 160px;
  height: 80px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  &:after{
    content: '';
    width: 160px;
    height: 160px;
    border:40px solid;
    border-color : rgba(0,0,0,0.15) rgba(0,0,0,0.15) var(--fill) var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing : border-box;
    transform: rotate( calc( 1deg * ( -45 + var(--percentage) * 1.8 ) ) );
    animation : fillAnimation 1s ease-in;
  }
}

@keyframes fillAnimation{
  0%{transform : rotate(-45deg);}
  50%{transform: rotate(135deg);}
}

@keyframes fillGraphAnimation{
  0%{transform: rotate(0deg);}
  50%{transform: rotate(180deg);}
}