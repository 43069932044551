.btn-search-div {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: end;
}

.table-list-plain {
  .table-switch-funds {
    max-height: 390px;
  }
  .table-in-page {
    border-radius: 0px;
    border-top: 0px;
    border-bottom: 0px;
    .table {
      tbody {
        tr:first-child {
          th,
          td {
            border-top: 0px;
          }
        }
      }
    }
  }
  .content-sec {
    padding: 6px 0px;
    width: max-content;
    .sec-header {
      font-size: 11px;
      color: $color-grey;
    }
    .moredetails-content {
      font-size: 14px;
      color: $color-grey3;
      font-weight: $font-weight-bold;
    }
  }
  .serial-no {
    font-size: 12px;
    color: $color-grey3;
    opacity: 0.5;
  }
  .button-sec {
    .btn {
      &.btn-tertiary {
        min-width: 116px;
        // width: max-content;
        margin-left: auto;
      }
    }
  }
  .warning-txt {
    padding-left: 18px;
  }
}

.content-last {
  height: 100%;
  display: flex;
  align-items: center;
}

.warning-txt {
  .txt-red {
    font-size: 14px;
    color: $color-red1;
  }
}

.status-sec {
  display: flex;
  align-items: center;
  width: max-content;
  font-weight: $font-weight-semibold;
  padding: 6px 0px;
  &.status-prog {
    color: $color-primary;
  }
  &.status-hold {
    color: $color-red1;
  }
  &.status-resolved {
    color: $color-green2;
  }
}

.track-modal {
  .card-form {
    .content-sec {
      .moredetails-content {
        font-weight: $font-weight-exbold;
      }
    }
  }
}

@media (max-width: 767px) {
  .content-last {
    height: unset;
  }

  .btn-search-div {
    justify-content: center;
  }
}
