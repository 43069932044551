.dropdown-menu {
    margin-top: 8px;
    padding: 8px 20px 8px 20px;
    border-radius: 10px;
    box-shadow: 6px 6px 30px 0 rgba(82, 154, 255, 0.16);
    border: solid 1px #c2e2f2;
    background-color: #fff;
    .dropdown-divider {
        border-color: #c2e2f2;
    }
    .dropdown-item {
        padding: 12px 10px 12px 0px;
        color: #7d7d8c;
        font-size: 12px;
        font-family: "Lexend";
        display: flex;
        &:hover {
            color: #074aa3;
            background: transparent;
            .arrow-right {
                display: none !important;
            }
            .arrow-right-blue {
                display: flex !important;
            }
        }
    }
}