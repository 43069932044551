.riderdetails-section {
    h2{
        font-family: "Nunito Sans", sans-serif !important;
        font-size: 18px;
        color: $color-light-black;
        font-weight: 800;
        small{
            font-size: 11px;
            margin-left: 5px;
            @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                display: block;
                margin-left: 0px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                display: block;
                margin-left: 0px;
            }
        }
    }
    hr{
        background-color:$color-light-blue1;
    }
    .custom-p-5 {
        padding:0px 10px;
      }
    .custom-p-8 {
        padding: 12px 8px;
    }
    .custom-pad-8 {
        padding: 12px 8px;
        @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding: 5px 0px;
            margin-left: -5px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding: 5px 0px;
            margin-left: -5px;
        }
    }
    .iconblock{
        padding-right: 60px;
        img{
            display: flex;
            @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                display:inline-block;
                margin-right: 6px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                display:inline-block;
                margin-right: 6px;
            }
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            padding-right:0px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            padding-right:0px;
        }
        @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-right:0px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-right:0px;
        }
    }
    .bigdes{
        font-size: 14px;
    }
    .smalltitle{
        font-size: 12px;
        font-weight: normal;
    }
    .content{
        font-size: 16px;
        font-weight: 700;
    }

    //Progress bar
    .hprogress {
        margin:15px 75px;
        width: 130px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            margin:15px 0px 15px 75px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            margin:15px 0px 15px 75px;
        }
        @include for-phone-only {
          margin-top: 30px;
          margin-left: auto;
          margin-right: auto;
        }
        .hprogressline {
          border-top: 1px solid $color-grey4;
          width: 50px;
          z-index: 1;
          margin-top: -5px;
        }
        .hprogressline-active {
          border-top: 1px solid $color-blue1;
          width: 50px;
          z-index: 1;
          margin-top: -5px;
        }
        .hicon {
          width: 10px;
          height: 10px;
          background-color: $color-grey4;
          border-radius: 5px;
          display: inline-block;
          margin-top: -10px;
          z-index: 2;
        }
        .hicon-activ {
          width: 10px;
          height: 10px;
          background-color: $color-blue1;
          border-radius: 5px;
          display: inline-block;
          margin-top: -10px;
          z-index: 2;
        }
      }
}

.riderdetails-popup{
    .card{
        &.card-white{
            padding:10px 15px; 
            color: $color-grey3;
            box-shadow: none;
            .col-4{
                @include for-phone-only {
                    padding-right: 0;
                    letter-spacing: -0.3px;
                 }
            }
            .col-md-2{
                white-space: nowrap;
            }
            .divider{
                border-right: 1px solid $color-light-blue1;
                margin-right: 15px;
                hr{
                    overflow: hidden;
                    display: none;
                    background-color: $color-light-blue1;
                    border: 0;
                }
                @include for-phone-only {    
                    border-right:0;
                    hr{
                        overflow:visible;
                        display: block;
                        background-color: $color-light-blue1;
                        border: 0;
                        height: 1px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
            .boxtitle{
                font-size: 11px;
                opacity: 0.9;
                @include for-phone-only {
                    font-size: 10px;
                 }
            }
            .boxcontent{
                font-size: 16px;
                font-weight: 800;
                @include for-phone-only {
                    font-size: 14px;
                }
            }
        }
    }
    .warning-text {
        font-size: 10px;
        color: #db3333;
        display: flex;
        align-items: center;
        column-gap: 6px;
        margin: 10px 0px;
    }
    .custom-checkbox {
        display: flex;
        align-items: center;
        justify-content:flex-end;
        margin-top: 0px;
    }
    .addriderradio-box{
        padding:0px 0px 20px 0px;
    }
    .firstscreenp{
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .ml-25{
        margin-left: 30px;
    }
    .totalfscreenlast{
        padding-top:20px;
        text-align: right;
        line-height: 34px;
    }
    .firstfooter{
        background-color: #F3F9FF;
    }
    .popuponebuttons{
        .btn-secondary{
            margin-right: 5px;
        }
    }
}