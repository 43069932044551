.my-policies-section {
  .page-section {
    padding-right: 0;
    h2.light {
      font-size: 36px;
    }
  }
}
.policy-detail {
  .card {
    &.card-blue {
      padding: 20px;
      min-height: 60px;
      .policy-number-heading,
      .policy-date-heading {
        font-size: 12px;
        color: $color-lighter-grey;
        margin: 0 0 5px;
        text-align: left;
        @include for-tablet-portrait-up {
          letter-spacing: -0.36px;
          white-space: nowrap;
          min-height: auto;
        }
        @include for-phone-only {
          font-size: 10px;
          letter-spacing: -0.36px;
          white-space: nowrap;
          min-height: auto;
        }
      }
      .policy-number {
        font-size: 18px;
        color: $color-white;
        font-weight: 800;
        letter-spacing: -0.18px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
          display: none !important;
          &.ipad-p {
            display: block !important;
            font-size: 16px;
          }
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
          display: none !important;
          &.ipad-p {
            display: block !important;
            font-size: 16px;
          }
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
          display: none !important;
          &.ipad-p {
            display: block !important;
            font-size: 16px;
          }
        }
        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
          display: none !important;
          &.ipad-p {
            display: block !important;
            font-size: 16px;
          }
        }
      }
      .policy-date {
        color: $color-white;
        font-size: 18px;
        font-weight: 800;
        @include for-phone-only {
          font-size: 15px;
        }
      }

      .insurance-name {
        font-size: 18px;
        margin: 0 0 5px;
        color: #fff;
      }
      .plan-name {
        font-size: 14px;
        color: $color-lighter-grey;
      }
      .titleborder {
        border-right: 1px solid $color-light-blue6;
        hr {
          overflow: hidden;
          visibility: hidden;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        @include for-phone-only {
          border-right: 0px;
          hr {
            overflow: visible;
            visibility: visible;
            background-color: $color-light-blue6;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .bottom-btn-block {
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      display: unset !important;
      .primary-btn-sec {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding-top: 30px;
        .btn-primary {
          margin-right: 10px !important;
          min-width: 135px !important;
        }
        .btn-secondary {
          min-width: 135px !important;
          padding: 8px 13px;
          margin-right: 10px;
        }
      }
      .sec-btn-block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 14px;
      }
    }
    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      display: unset !important;
      .primary-btn-sec {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding-top: 30px;
        .btn-primary {
          margin-right: 10px !important;
          min-width: 135px !important;
        }
        .btn-secondary {
          min-width: 135px !important;
        }
      }
      .sec-btn-block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 14px;
      }
    }
  }

  .anotherpolicy {
    .custom-select {
      width: 290px;
      @include for-phone-only {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .form-control:focus ~ label {
    bottom: 60px;
    font-size: 10px;
    text-transform: capitalize;
    display: none;
  }
  .form-control:valid ~ label {
    bottom: 60px;
    font-size: 10px;
    text-transform: capitalize;
    display: none;
  }
  .addresshide {
    display: none;
  }
  .cutomradiotext {
    font-weight: normal;
    padding-top: 10px;
  }
  .custom-p-5 {
    padding: 10px;
  }
  .custom-p-8 {
    padding: 12px 8px;
  }
  #rider {
    .custom-p-8 {
      padding: 12px 8px;
      @include for-phone-only {
        padding: 12px 0px;
      }
    }
  }
  .inforce {
    font-size: 16px;
    color: $color-green;
    @include for-phone-only {
      text-align: left;
    }
  }
  .riderbu {
    min-width: 110px;
    @include for-phone-only {
      min-width: 130px;
      float: left;
    }
  }
  .fundetails {
    border-radius: 10px;
    border: solid 1px $color-light-blue1;
    @include for-phone-only {
      margin-left: -20px;
      margin-right: -20px;
      border-radius: 0px;
      .custompl-8 {
        padding-left: 8px;
      }
      .custompr-8 {
        padding-right: 9px;
      }
    }
    table {
      border-collapse: collapse;
      border-radius: 10px;
      overflow: hidden;
      font-size: 14px;
      @include for-phone-only {
        font-size: 10px;
        border-radius: 0px;
        td {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    thead {
      background-color: $color-light-blue2;
      color: $color-grey !important;
      text-transform: uppercase;
      letter-spacing: 0.65px;
    }
    tfoot {
      background-color: $color-light-blue2;
      line-height: 67px;
    }
    .totalcount {
      color: $color-grey !important;
      text-transform: uppercase;
      font-weight: 600;
    }
    th {
      border: none;
      font-weight: 600;
      .fundalco {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
          display: none !important;
          &.ipad-p {
            display: block !important;
          }
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
          display: none !important;
          &.ipad-p {
            display: block !important;
          }
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
          display: none !important;
          &.ipad-p {
            display: block !important;
          }
        }
        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
          display: none !important;
          &.ipad-p {
            display: block !important;
          }
        }
      }
    }
    td {
      border-color: $color-light-blue1;
    }
  }
  .nav {
    @media (max-width: 575px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .nav-tabs {
    margin-top: 40px;
    border-bottom: 1px solid rgba(154, 154, 173, 0.5);
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      white-space: nowrap;
    }
    .nav-item {
      width: 25%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
  .top-section {
    h2 {
      letter-spacing: -0.01rem;
    }
    .sec-header {
      font-size: 11px;
      color: $color-grey;
      @include for-phone-only {
        text-align: left;
        font-size: 10px;
      }
    }
    .hideone {
      @include for-phone-only {
        display: none;
      }
    }
    .content {
      font-size: 16px;
      font-weight: 800;
      color: #51515c;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        font-size: 14px;
      }
      @include for-phone-only {
        font-size: 14px;
        text-align: left;
      }
    }
    .moredetails-content {
      font-size: 16px;
      font-weight: 700;
      color: #51515c;
      @include for-phone-only {
        font-size: 12px;
      }
      @include for-tablet-portrait-up {
        font-size: 14px;
        letter-spacing: -0.75px;
      }
    }
    .riderdetailsbu {
      font-size: 14px;
      color: $color-primary;
      font-weight: bold;
      cursor: pointer;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        font-size: 14px;
      }
      @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        font-size: 14px;
      }
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        font-size: 14px;
        text-align: left;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        font-size: 14px;
        text-align: left;
      }
      @include for-phone-only {
        font-size: 14px;
        float: left;
      }
    }
    hr {
      background-color: $color-light-blue1;
    }
    .extragap {
      padding-left: 54px;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        padding-left: 0px;
      }
      @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        padding-left: 0px;
      }
      @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding-left: 0px;
      }
      @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding-left: 0px;
      }
    }
  }
  .table {
    margin-bottom: 0px;
    td {
      @include for-phone-only {
        padding: 0.6rem;
      }
    }
  }
  // graytext link
  .graytextbu {
    font-size: 14px;
    color: $color-grey;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      font-size: 14px;
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
      font-size: 14px;
    }
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      font-size: 14px;
      text-align: left;
    }
    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      font-size: 14px;
      text-align: left;
    }
    @include for-phone-only {
      font-size: 14px;
      float: left;
    }
  }
  // Completetext link
  .completetext {
    text-align: right;
    font-size: 12px;
    color: $color-green2;
    img {
      margin-right: 12px;
    }
  }
}

.datepicker {
  th {
    padding: 5px;
    color: $color-grey3 !important;
  }
  td {
    padding: 5px;
    color: $color-grey3 !important;
  }
  td.active {
    background-image: linear-gradient(to bottom, #0086c1, #0086c1) !important;
  }
  td.old {
    color: #fff !important;
  }
  table tr td.new {
    display: none;
  }
  table tr td.old {
    visibility: hidden;
  }
}

.breadcrumbLinks {
  li {
    font-size: 12px;
    color: #a6a6b3;
    a {
      color: #0086c1;
    }
  }
}

// Switch Funds Table Stylings Starts

.table-switch-funds {
  max-height: 410px;
  position: relative;
  overflow-y: scroll;
  border-top: 1px solid #c2e2f2;
  border-bottom: 1px solid #c2e2f2;
  direction: rtl;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.12);
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.12);
  //   background-color: transparent;
  // }

  // &::-webkit-scrollbar {
  //   width: 4px;
  //   height: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #c1d6f2;
  //   border-radius: 10px;
  // }

  .table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0px;
    direction: ltr;
    thead {
      position: sticky;
      top: 0;
      background-color: #e3eefc;
      color: #7c7c8a;
      text-transform: uppercase;
      z-index: 2;
      th {
        text-align: right;
        border-top: 0px;
        border-bottom: 0px;
        vertical-align: middle;
        .table-heading {
          font-size: 12px;
          display: block;
          padding-top: 5px;
          padding-bottom: 5px;
          line-height: 1.5;
        }
      }
      .thead-first-column {
        text-align: left;
        position: sticky;
        left: 0;
        background-color: #e3eefc;
      }
    }
    tbody {
      th {
        font-size: 14px;
        position: sticky;
        left: 0;
        background-color: #fff;
        vertical-align: middle;
        border-color: #b9ddf0;
        color: #51515c;
        .table-column-first {
          min-width: 120px;
        }
      }
      td {
        text-align: right;
        font-size: 14px;
        border-color: #b9ddf0;
        vertical-align: middle;
        .table-content {
          display: block;
          width: max-content;
          margin-left: auto;
        }
        .table-content1 {
          display: block;
        }
      }
    }
    tfoot {
      position: sticky;
      bottom: 0;
      background-color: #e3eefc;
      z-index: 1;
      text-transform: uppercase;
      td {
        text-align: right;
        border-top: 0px;
        font-size: 12px;
        vertical-align: middle;
        .table-footer {
          display: block;
          padding-top: 3px;
          padding-bottom: 3px;
          width: max-content;
          margin-left: auto;
        }
        strong {
          font-size: 14px;
        }
      }
      .tfoot-first-column {
        position: sticky;
        left: 0;
        background-color: #e3eefc;
        text-align: left;
        color: #7c7c8a;
        font-weight: 600;
        .table-footer {
          margin-right: auto;
          margin-left: unset;
        }
      }
    }

    .table-input {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      input[type="number"] {
        text-align: right;
        height: 44px;
        width: 90px;
        border-radius: 10px;
        border: 1px solid #c2e2f2;
        outline: none;
        padding: 0px 12px;
        /* Firefox */
        -moz-appearance: textfield;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &:focus {
          border-color: #0c87c1;
          box-shadow: 6px 6px 30px #529aff29;
        }
      }
      .percent-span {
        padding-left: 10px;
      }
      .txt-desc {
        padding-left: 10px;
        font-size: 12px;
        text-align: left;
        width: max-content;
      }
      &.warning-input {
        input[type="number"] {
          &:focus {
            border-color: #c8433c;
          }
        }
      }
    }
  }
}

.table-custom-padding {
  .table {
    thead {
      .thead-first-column {
        .table-heading {
          padding-left: 8px;
        }
      }
    }
    tbody {
      th {
        .table-column-first {
          padding-left: 8px;
        }
      }
    }
    tfoot {
      .tfoot-first-column {
        .table-footer {
          padding-left: 8px;
        }
      }
    }
  }
}

.table-future-pre {
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  border-bottom: 0px;
  max-height: 380px;
  .allocate-nav {
    font-family: "Lexend";
    color: #074aa3;
    font-weight: bold;
    display: block;
    height: 35px;
    width: max-content;
    padding: 8px 15px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-left: auto;
    > img {
      margin-left: 1px;
      margin-bottom: 3px;
    }
    &:hover {
      background: #e3eefc;
      // font-weight: normal;
    }
  }
  .deactivate-nav {
    font-family: "Lexend";
    color: $color-grey3;
    font-weight: bold;
    display: flex;
    height: 35px;
    width: max-content;
    padding: 0px 15px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-left: auto;
    > img {
      margin-left: 5px;
      margin-top: 2px;
    }
    &:hover {
      background: #e3eefc;
      // font-weight: normal;
    }
  }
}

.table-in-page {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .table {
    tbody {
      th {
        background-color: #f9fcff;
        z-index: 1;
      }
    }
  }
}

.table-two {
  .table {
    th,
    td {
      border-color: #b9ddf0;
    }
    thead {
      th {
        border-top: 1px solid #b9ddf0;
      }
    }
  }
}

// .bdl-apply {
//   border-left: 1px solid #b9ddf0;
// }

// .bdr-apply {
//   border-right: 1px solid #b9ddf0;
// }

.table-layout-section {
  .custom-card {
    border: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 6px 6px 30px #529aff29;
    .card-footer {
      border: 0px;
      background-color: #fff;
      padding: 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .row-bottom {
        align-items: center;
      }
      .warning-text {
        font-size: 11px;
        color: #db3333;
        display: flex;
        align-items: center;
        column-gap: 6px;
      }
      .btn-div {
        text-align: center;
        margin: 0px auto 20px;
      }
      .btns-div {
        text-align: right;
      }
    }
  }
}

.updated-div {
  font-size: 12px;
  color: $color-grey3;
}

.blue-light {
  color: #0B86C1;
}

.left-right-indicator {
  display: none;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding-bottom: 20px;
  .arrow-rt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 16px;
    background-color: #e3eefc;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .arrow-lt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 16px;
    background-color: #e3eefc;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  p {
    font-size: 10px;
    color: #7d7d8c;
    margin-bottom: 0px;
  }
}

.left-right-indicator-one {
  display: none;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding-bottom: 20px;
  margin-top: 30px;
  .arrow-rt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 16px;
    background-color: #e3eefc;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .arrow-lt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 16px;
    background-color: #e3eefc;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  p {
    font-size: 10px;
    color: #7d7d8c;
    margin-bottom: 0px;
  }
}

.total-percent {
  padding-right: 22px;
  &.tpercent-warning {
    color: #db3333;
  }
}

.success-percent-list {
  border: 1px solid #c2e2f2;
  padding: 0px 20px;
  margin: 20px;
  border-radius: 10px;
  .table {
    margin-bottom: 0px;
    tbody {
      tr:first-child {
        td {
          border-top: 0px;
        }
      }
    }
  }
}

.back-arrow {
  margin-right: 20px;
  cursor: pointer;
}

.details-card {
  margin-top: 10px;
  margin-bottom: 25px;
  .card {
    &.card-white {
      padding: 20px;
      color: #51515c;
      box-shadow: none;
      .divider {
        border-right: 1px solid #b9ddf0;
        hr {
          overflow: hidden;
          display: none;
          background-color: #b9ddf0;
          border: 0;
        }
      }
      .boxtitle {
        font-size: 11px;
        opacity: 0.9;
      }
      .boxcontent {
        font-size: 16px;
        font-weight: 800;
      }
    }
  }
}

.font-css {
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
}

.green-text {
  color: #00b200;
}

@media only screen and (max-width: 575px) {
  .details-card {
    .card {
      &.card-white {
        .divider {
          border-right: 0;
          hr {
            overflow: visible;
            display: block;
            height: 1px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
        .boxtitle {
          font-size: 10px;
        }
        .boxcontent {
          font-size: 14px;
        }
      }
    }
  }
  .btns-align {
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    .btn1.btn-primary {
      // min-width: 160px;
      width: 100%;
      padding: 10px 20px;
    }
    .btn1.btn-secondary {
      // min-width: 160px;
      width: 100%;
      padding: 10px 20px;
      margin-right: 0px;
    }
  }
  .table-switch-funds {
    .table {
      .table-input {
        input[type="number"] {
          width: 47px;
          height: 38px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .left-right-indicator {
    display: flex;
  }
  .left-right-indicator-one {
    display: flex;
  }
  .table-switch-funds {
    max-height: 340px;
    .table {
      thead {
        th {
          .table-heading {
            font-size: 9px;
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }
        .thead-first-column {
          border-right: 1px solid #b9ddf0;
        }
      }
      tbody {
        th {
          font-size: 12px;
          border-right: 1px solid #b9ddf0;
        }
        td {
          font-size: 12px;
        }
      }
      tfoot {
        td {
          font-size: 9px;
          strong {
            font-size: 12px;
          }
        }
        .tfoot-first-column {
          border-right: 1px solid #b9ddf0;
        }
      }
    }
  }

  .table-in-page {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .table-layout-section {
    margin-left: -20px;
    margin-right: -20px;
    .custom-card {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .card-footer {
        padding: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        .warning-text {
          padding: 10px 0px;
          font-size: 10px;
        }
        .row-bottom {
          margin: 0px;
        }
        .btns-div {
          display: flex;
          padding: 15px 0px;
          border-top: 1px solid #c2e2f2;
          margin-left: -15px;
          margin-right: -15px;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .left-right-indicator-one {
    display: flex;
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
  .left-right-indicator-one {
    display: flex;
  }
}

.ridertab-rightalign {
  text-align: right;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    text-align: left;
  }
}
// Switch Funds Table Stylings Ends
