// side nav 
.side-nav {
    background: $gradient-blue;
    width: 240px;
    height: 100vh;
    padding: 25px 0px 0px 30px;
    font-family: "Lexend", sans-serif !important;
    position: fixed;
    z-index: 999;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        display: none;
    }
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        display: none;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
        display: none;
    }
    .nav-link {
        width: 100%;
        font-size: 13px;
        color: $color-white;
        padding: 15px;
        margin: 2px 0px;
        position: relative;
        height: 46px;
        &.with-opacity {
            color: #9DBEDD;
            &.active {
                background-color: #3C84BF;
                border-radius: 10px;
                width: 190px;
                color: $color-white;
            }
        }
        &.active {
            color: $color-primary;
            background-color: $color-light-blue5;
            font-weight: 600;
            border-radius: 10px 0px 0px 10px !important;
            position: relative;
            &::before {
                content: url("./../assets/images/shape.svg");
                position: absolute;
                right: 0;
                top: -12px;
                @media only screen and (min-width: 1900px) {
                    top: -12px;
                    @media only screen and (min-width: 1900px) {
                        //top: -12px;
                    }
                }
            }
            &::after {
                content: url("./../assets/images/shape.svg");
                position: absolute;
                right: -2px;
                width: 14px;
                bottom: -13px;
                transform: rotate(270deg);
                @media only screen and (min-width: 1900px) {
                    bottom: -12px;
                }
            }
        }
    }
    .nav-link[aria-expanded="true"] {
        padding: 6px 12px !important;
        border-radius: 50px !important;
        background-color: #e3eefc !important;
    }
    .nav-link1 {
        width: 100%;
        font-size: 13px;
        color: $color-white;
        padding: 15px;
        margin: 2px 0px;
        position: relative;
        height: 46px;
        cursor: pointer;
        &.with-opacity {
            color: #9DBEDD;
            &.active {
                background-color: #3C84BF;
                border-radius: 10px;
                width: 190px;
                color: $color-white;
            }
        }
        &.active {
            color: $color-primary;
            background-color: $color-light-blue5;
            font-weight: 600;
            border-radius: 10px !important;
            position: relative;
        }
    }
    .nav-link1[aria-expanded="true"] {
        padding: 6px 12px !important;
        border-radius: 50px !important;
        background-color: #e3eefc !important;
    }
}