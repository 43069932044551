.policies-page{
    padding: 30px 0px 30px 40px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        padding: 30px 20px 30px 20px;
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        padding: 30px 20px 30px 20px;
    }
    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 30px 20px 30px 20px;
    }
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 30px 20px 30px 20px;
    }

    .tab-content {
        width: 100%;
        padding-left: 240px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            padding-left: 0px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            padding-left: 0px;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            padding-left: 0px;
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-left: 0px;
        }
    }


}