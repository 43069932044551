.makepayment-content {
  padding: 35px 13px;
  .accordion-section-div {
    .accordion-main {
      padding-bottom: 0px;
      .accordion-header {
        .heading-toggle {
          justify-content: start;
          border-bottom: none;
          .img-hover {
            display: none;
          }
        }
        .btn[aria-expanded="true"] {
          .img-normal {
            display: none;
          }
          .img-hover {
            display: block;
          }
        }
      }
    }
    .card-accordion {
      .card-body {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .button-single {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-top: 20px;
    }
  }
}

.section-accordion {
  .section-each {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .form-group {
    margin-bottom: 0px;
  }
}

.sec-get-in-touch {
  font-size: 12px;
  .grey-content {
    color: #9a9aad;
    line-height: 1.7;
  }
  .link-txt {
    font-size: 14px;
    color: #9a9aad;
  }
}

@media (max-width: 767px) {
  .makepayment-content {
    .accordion-section-div {
      .button-single {
        padding-top: 0px;
      }
    }
  }
}

// Error and Login Page Starts

.img-section {
  .g-txt {
    font-size: 18px;
    color: $color-grey2;
  }
}

@media (max-width: 767px) {
  .section-error,
  .section-login {
    padding-top: 45px;
  }
  .section-login {
    padding-bottom: 80px;
  }
  .img-section {
    .g-txt {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .section-error,
  .section-login {
    padding-top: 35px;
  }
  .section-login {
    padding-bottom: 100px;
  }
}

// Error and Login Page Ends