
.half-apexchart {
    width: 100%;
    text-align: center;
    top: 0;
      input[type="range"] {
          --thumbSize: 16px;
          --trackSize: 4px;
          --thumbBg: #356CC4;
          --trackBg: #DEDEDE;
          --progressBg: #356CC4;
        
          /* webkit progress workaround */
          --webkitProgressPercent: 0%;
        }
        
        input[type="range"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          height: var(--thumbSize);
          width: 100%;
          margin: 0;
          padding: 0;
        }
        input[type="range"]:focus {
          outline: none;
        }
        
        /* Thumb */
        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: var(--thumbSize);
          height: var(--thumbSize);
          background-color: var(--thumbBg);
          border-radius: calc(var(--thumbSize) / 2);
          border: none;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
          cursor: pointer;
        }
        input[type="range"]::-moz-range-thumb {
          -moz-appearance: none;
          appearance: none;
          width: var(--thumbSize);
          height: var(--thumbSize);
          background-color: var(--thumbBg);
          border-radius: calc(var(--thumbSize) / 2);
          border: none;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
          cursor: pointer;
        }
        input[type="range"]::-ms-thumb {
          -ms-appearance: none;
          appearance: none;
          width: var(--thumbSize);
          height: var(--thumbSize);
          background-color: var(--thumbBg);
          border-radius: calc(var(--thumbSize) / 2);
          border: none;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
          cursor: pointer;
        }
        
        /* Track */
        input[type="range"]::-webkit-slider-runnable-track {
          height: var(--trackSize);
          background-image: linear-gradient(
            90deg,
            var(--progressBg) var(--webkitProgressPercent),
            var(--trackBg) var(--webkitProgressPercent)
          );
          border-radius: calc(var(--trackSize) / 2);
        }
        input[type="range"]::-moz-range-track {
          height: var(--trackSize);
          background-color: var(--trackBg);
          border-radius: calc(var(--trackSize) / 2);
        }
        input[type="range"]::-ms-track {
          height: var(--trackSize);
          background-color: var(--trackBg);
          border-radius: calc(var(--trackSize) / 2);
        }
        
        /* Progress */
        input[type="range"]::-moz-range-progress {
          height: var(--trackSize);
          background-color: var(--progressBg);
          border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
        }
        input[type="range"]::-ms-fill-lower {
          height: var(--trackSize);
          background-color: var(--progressBg);
          border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
        }
  }