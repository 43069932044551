.download-section {
  .page-section {
    h2.light {
      font-size: 36px;
      padding-bottom: 0px !important;
    }
  }

ul.download-content{
    padding-top: 30px;
    color: $color-grey3;
    li{
        border-bottom: 1px solid rgba(185, 221, 240,.7);
        padding: 20px 0;
        font-size: 16px !important;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            font-size: 14px !important;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            font-size: 14px !important;
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            font-size: 14px !important;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            font-size: 14px !important;
        }
        a{
            font-family: "Lexend" !important;
            font-size: 12px;
            float: right;
            margin:0px 20px;
            img{
                margin-right: 5px;
            }
            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding-top: 25px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding-top: 25px;
            }
        }
        small{
            font-size: 12px;
            font-weight: normal;
            color: $color-light-grey;
        }
    }
    li:last-child{
      border-bottom: 0px solid rgba(185, 221, 240,.7);
    }
    //select
/*the container must be positioned relative:*/
.custom-select {
    position: relative;
    width: 163px;
    height: 35px !important;
    background: $color-white !important;
    border: 1px solid $color-border !important;
    border-radius: 10px !important;
    margin-top: -10px;
    margin-bottom: -10px;
    float: right;
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        width: 100%;
        margin-top: 16px;
    }
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        width: 100%;
        margin-top: 16px;
    }
    &:focus,
    &:hover {
        box-shadow: 6px 6px 30px #529aff29 !important;
        border: 1px solid #0c87c1 !important;
        border-radius: 10px !important;
    }
    &:disabled {
        border: 1px solid $color-border !important;
        cursor: not-allowed;
        box-shadow: none !important;
    }

  }
  
  .custom-select select {
    display: none; /*hide original SELECT element:*/
    background-color: transparent;
  }
  
  .select-selected {
    color: $color-light-grey;
    border-bottom:0 !important;
    font-size: 14px !important;
  }
  
  /*style the arrow inside the select element:*/
  .select-selected:after {
    position: absolute;
    content: url(./../assets/images/select-gray-arrow.svg);
    transform: rotate(0deg);
    top:4px;
    right:26px;
    width: 0;
    height: 0;
}
  
  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
    top: 28px;
    right:4px;
    content: url(./../assets/images/select-gray-arrow.svg);
    transform: rotate(180deg);
  }
  
  /*style the items (options), including the selected item:*/
  .select-items div,.select-selected {
    color: $color-light-grey;
    padding:0px !important;
    border: 1px solid transparent;
    border-color: transparent transparent $color-border transparent;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    &:last-child {
      border-bottom: transparent;
    }

  }
  .select-items div{
      padding: 8px 0 !important;
  }
  /*style items (options):*/
  .select-items {
    position: absolute;
    background: $color-white !important;
    border: 1px solid $color-border !important;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    padding:10px;
    border-radius:10px;
    margin-top: 5px;
  }
  
  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }
  
  .select-items div:hover, .same-as-selected {
      color: $color-primary;
      text-overflow:clip;
      white-space: inherit;
  }
}

  select {
    border: 1px solid $color-border;
    padding: 12px;
    border-radius: 10px;
    color: $color-light-grey;

    @include for-phone-only {
      width: 100%;
      margin-top: 20px;
    }
  }
}




// Policy Services Stylings by Salman Ends
