*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  line-height: normal;
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}

html {
  body {
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
    background: $color-light-blue5;
    a {
      color: $color-secondary;
      display: inline-block;
      &:focus,
      &:hover {
        text-decoration: none;
        color: $color-primary;
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    &.customer-portal.modal-open {
      position: relative !important;
      overflow-y: scroll;
      width: 100%;
      padding-right: 0px !important;
    }
    &.login.modal-open {
      position: relative !important;
      overflow-y: scroll;
      width: 100%;
      padding-right: 0px !important;
      .iconModal .modal-content {
        //right: -26px;
      }
    }
  }
}
.custom-plr-4 {
  padding-left: 8px;
  padding-right: 8px;
}
.custom-plr-10 {
  padding-left: 40px;
  padding-right: 40px;
}
.ltspace70 {
  letter-spacing: -0.6px;
}
.custom-p-4 {
  padding: 8px;
}
.pb1 {
  padding-bottom: 1px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-26 {
  margin-top: 28px;
}
.mt-10 {
  margin-top: 10px;
}
.pt-30 {
  padding-top: 40px;
}
.pt-20 {
  padding-top: 30px;
}
.pt-10 {
  padding-top: 10px;
}
.mr-10 {
  margin-right: 10px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin-right: 5px;
  }
}
.mt-20less {
  margin-top: -20px;
}
.custom-pt-8 {
  padding: 0 8px;
}
.disabledlable {
  background-color: transparent !important;
}
.textgreen {
  color: $color-green;
}
.lspace-12 {
  letter-spacing: -0.45px;
}
.cp-specalp-30 {
  padding-top: 30px;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    padding-top: 0px !important;
  }
  @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
    padding-top: 0px !important;
  }
  @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
    padding-top: 0px !important;
  }
  @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
    padding-top: 0px !important;
  }
}
.custom-p-2 {
  padding: 12px 0px 12px 8px;
  @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
    padding: 0px 15px 0px 10px !important;
  }
  @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
    padding: 0px 15px 0px 10px !important;
  }
}
.deactivate-block {
  .custom-p-2 {
    padding: 12px 0px 0px 8px;
    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
      padding: 0px 15px 0px 10px !important;
    }
    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
      padding: 0px 15px 0px 10px !important;
    }
  }
}
// sticky block scss
.sticky-block {
  .sticky-icon {
    position: fixed;
    bottom: 30px;
    //right: 40px;
    z-index: 10;
    &:hover {
      .not-hover-state {
        display: none;
      }
      .hover-state {
        display: flex;
      }
    }
  }
}

.anotherpolicy {
  .custom-select {
    width: 290px;
    @include for-phone-only {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.nomine-p-2 {
  padding: 0px 0px 0px 4px;
  margin-top: -2px;
  @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
    padding: 0px 5px 0px 5px !important;
    margin-top: 3px;
    margin-left: 0px;
  }
  @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
    padding: 0px 5px 0px 5px !important;
    margin-top: 3px;
    margin-left: 0px;
  }
}

.f-18 {
  font-size: 18px;
}

.pb-8 {
  padding-bottom: 8px;
}
.pb-6 {
  padding-bottom: 6px;
}
.custom-plr-3 {
  padding-left: 3px;
  padding-right: 3px;
}
.mt-15 {
  margin-top: 15px;
}
.custom-p-58 {
  padding: 12px 0px 12px 8px;
}

.grey-light {
  color: $color-grey2;
}

.grey-light3 {
  color: $color-grey3;
}

.border-line {
  border-right: 1px solid $color-light-blue1;
  @media (max-width: 767px) {
    border-right: none;
  }
}
.custom-p-58{
    padding: 12px 0px 12px 8px;
}
.ml-custom5{
    margin-left: 5px;
}
.mb-20{
    margin-bottom: 20px;
}

.apexcharts-datalabels-group{
  display: none;
}

.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-50less{
  margin-top: -50px;
}
