.footer {
    margin-top: 80px;
    padding: 50px 84px 40px 280px;
    background: $color-grey3;
    @media only screen and (min-width: 1919px) {
        padding-left: 446px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        padding-left: 20px !important;
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        padding-left: 40px !important;
    }
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        padding-left: 40px !important;
    }
    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 40px 20px 45px 20px !important;
        .bottom-text {
            padding: 15px !important;
        }
        .contact-us {
            padding-top: 0px !important;
        }
        .vr-line-bottom {
            opacity: 0.2;
            background-color: #fff;
            height: 1px;
            margin: 30px 15px 24px 15px;
        }
    }
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 40px 20px 45px 20px !important;
        .bottom-text {
            padding: 15px !important;
        }
        .contact-us {
            padding-top: 0px !important;
        }
        .vr-line-bottom {
            opacity: 0.2;
            background-color: #fff;
            height: 1px;
            margin: 30px 15px 24px 15px;
        }
    }
    .footer-links {
        li a {
            padding-bottom: 12px;
            color: $color-white;
            &.subtitle {
                opacity: 0.6;
                &.bold-text {
                    opacity: 1;
                    font-weight: bold;
                }
            }
        }
    }
    .vr-line {
        height: 100%;
        width: 1px;
        background: $color-white;
        opacity: 0.2;
    }
    .info-text {
        color: $color-white;
        opacity: 0.6;
        padding-bottom: 30px;
        margin-bottom: 0px;
    }
    h5.contact-us {
        font-weight: bold;
        color: $color-white;
    }
}

.login {
    overflow-x: hidden;
    .footer {
        margin-top: 100px;
        padding: 50px 170px 40px 170px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            padding: 50px 40px 40px 40px !important;
            margin-top: 0px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            padding: 50px 40px 40px 40px !important;
            margin-top: 0px;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            padding: 50px 40px 40px 40px !important;
            margin-top: 0px;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            margin-top: 0px;
        }
        @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            margin-top: 0px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            margin-top: 0px;
        }
    }
}