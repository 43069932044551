/* Key Frmae Animations */

// Slide Arrow Right
@keyframes slide1 {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(5px, 0);
    }
}

// Preloader
@-webkit-keyframes animate-preloader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animate-preloader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

// Move Left
@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

// Move Right
@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

// Move Bottom
@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(2rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

// Animation box preloader
@keyframes animateBefore {
    0% {
        bottom: -100%;
    }

    25% {
        bottom: 0%;
    }

    50% {
        bottom: 0%;
    }

    75% {
        bottom: 100%;
    }

    100% {
        bottom: 100%;
    }
}

@keyframes animateBox {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    25% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    50% {
        transform: translate(-50%, -50%) rotate(180deg);
    }

    75% {
        transform: translate(-50%, -50%) rotate(180deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}


@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba($color-primary, 0.5),
            0 0 0 1rem rgba($color-primary, 0.5) // 0 0 0 3rem rgba($color-primary, 0.3),
            // 0 0 0 5rem rgba($color-primary, 0.3);
    }

    100% {
        box-shadow: 0 0 0 1rem rgba($color-primary, 0.5),
            0 0 0 3rem rgba($color-primary, 0);
        // 0 0 0 5rem rgba($color-primary, 0.3),
        // 0 0 0 8rem rgba($color-primary, 0);
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}