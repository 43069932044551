.modal {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  .modal-dialog {
    margin: 1.75rem auto;
    @media (min-width: 481px) and (max-width: 767px) {
      margin: 1.75rem auto 0px auto;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      margin: 1.75rem auto 0px auto;
    }
  }
}

// icon modal stylings
.iconModal {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden !important;
  .modal-dialog {
    transition: none !important;
    transform: none !important;
    width: 100%;
    height: 100%;
    min-width: 100%;
  }
  .modal-content {
    background: transparent;
    border: none;
    width: 131px;
    position: absolute;
    //right: -43px;
    bottom: 58px;
  }
  .icon-sec {
    height: 50px;
  }
}

// video modal stylings
.video-modal {
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-header {
    position: absolute;
    width: 100%;
    z-index: 10;
    border: none;
    padding: 15px 22px;
    .title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
      color: $color-white;
    }
    .sub-title {
      font-size: 14px;
      color: $color-white;
    }
    .close {
      opacity: 1;
      font-size: 30px;
      color: white;
    }
  }
  .modal-body {
    padding: 0px;
    border-radius: 10px !important;
  }
  .modal-content,
  video {
    border-radius: 10px !important;
    border: none !important;
  }
  .modal-content {
    &:hover {
      .modal-title,
      .close {
        display: block !important;
      }
      .modal-header {
        background: linear-gradient(to top, transparent 0%, #000000ad 100%);
        border-radius: 10px;
      }
    }
  }
}

.email-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    padding: 24px 30px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    width: 430px;
    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 30px) !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: calc(100% - 30px) !important;
    }
    .choose-acc-text {
      letter-spacing: -0.14px;
      color: #7c7c8a;
      opacity: 1;
      font-size: 14px;
      padding-bottom: 34px;
    }
    .profile-image {
      padding-right: 12px;
    }
    .g-profile {
      padding-bottom: 24px;
    }
    .name {
      letter-spacing: -0.13px;
      color: $color-black;
      font-weight: 800;
      font-size: 13px;
    }
    .email {
      font-size: 11px;
      letter-spacing: -0.33px;
      color: #7c7c8a;
    }
    .add-account {
      border-top: 1px solid #c2e2f2;
      border-bottom: 1px solid #c2e2f2;
      padding: 18px 0px;
    }
    .note {
      letter-spacing: -0.33px;
      color: #7c7c8a;
      opacity: 1;
      font-size: 11px;
      padding-top: 24px;
    }
  }
}

.facebook-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    text-align: center;
    padding: 24px 30px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    width: 400px;
    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 30px) !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: calc(100% - 30px) !important;
    }
    .text {
      font-size: 14px;
      color: #7c7c8a;
      padding: 11px 0px 23px;
      border-bottom: 1px solid #c2e2f2;
    }
    .edit-this {
      font-size: 14px;
      font-weight: 600;
      padding: 20px 0px;
      color: #7c7c8a;
    }
    .bottom-text {
      font-size: 11px;
      color: #7c7c8a;
      padding-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        padding-right: 6px !important;
        margin-bottom: 2px !important;
      }
    }
  }
}

.otp-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    text-align: center;
    padding: 24px 60px 20px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    width: 400px;
    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 30px) !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: calc(100% - 30px) !important;
      padding: 24px 20px 20px;
    }
    .otp-text {
      line-height: 1.64;
      letter-spacing: -0.33px;
      text-align: center;
      color: #7c7c8a;
      font-size: 11px;
      padding-bottom: 8px;
    }
    .otp-text1 {
      line-height: 1.64;
      letter-spacing: -0.33px;
      text-align: center;
      color: #7c7c8a;
      font-size: 14px;
      padding-top: 15px;
    }
    .otpmessage {
      background-color: $color-grey;
      border-radius: 5px;
      color: $color-white;
      font-size: 14px;
      width: 145px;
      margin: 15px auto;
      padding: 8px 20px;
      text-align: left;
      small {
        font-size: 10px;
        display: block;
      }
      img {
        margin-bottom: 15px;
        margin-right: 10px;
        float: left;
        margin-top: 5px;
      }
    }
    .otp-type {
      font-weight: 800;
      letter-spacing: -0.26px;
      text-align: left;
      color: #0a4ca2;
      font-size: 13px;
    }
    .opt-input-block {
      padding-top: 20px;
      border-top: 1px solid #c2e2f2;
    }
    .verify-btn {
      margin-top: 30px;
    }
  }
}

.change-password-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    text-align: center;
    width: 500px;
    padding: 36px 40px 30px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 30px) !important;
      padding: 30px 26px 26px;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: calc(100% - 30px) !important;
      padding: 30px 26px 26px;
    }
    .btn {
      min-width: 170px;
      &.btn-primary {
        @media (min-width: 481px) and (max-width: 767px) {
          margin-right: 8px !important;
        }
        // (Portrait)
        @media (min-width: 320px) and (max-width: 480px) {
          margin-right: 8px !important;
        }
      }
      @media (min-width: 481px) and (max-width: 767px) {
        min-width: 140px;
      }
      // (Portrait)
      @media (min-width: 320px) and (max-width: 480px) {
        min-width: 130px;
      }
    }
    .help-text {
      line-height: 1.6;
      letter-spacing: -0.1px;
      text-align: left;
      color: #074aa3;
      font-size: 10px;
      position: absolute;
      top: 48px;
      left: 10px;
    }
  }
}

// payment modal stylings
.payment-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    .card {
      box-shadow: none;
      .col {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        @include for-phone-only {
          padding-bottom: 20px;
        }
      }
    }
  }
  .modal-dialog-centered {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    padding: 24px 20px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    min-width: 727px;
    @include for-phone-only {
      min-width: 100%;
      bottom: 0 !important;
      position: absolute;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: 100% !important;
    }
    .success-icon {
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
    }
  }
  .downloadtext {
    font-weight: normal !important;
    color: $color-grey3;
  }
}

// Success modal stylings
.success-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 24px;
      font-weight: 600;
      padding-top: 15px;
    }
    .choose-acc-text {
      font-size: 16px !important;
    }
  }
  .card {
    &.card-white {
      padding: 20px;
      color: $color-grey3;
      box-shadow: none;
      .col-4 {
        @include for-phone-only {
          padding-right: 0;
          letter-spacing: -0.3px;
        }
      }
      .col-md-2 {
        white-space: nowrap;
      }
      .divider {
        border-right: 1px solid $color-light-blue1;
        margin-right: 15px;
        hr {
          overflow: hidden;
          display: none;
          background-color: $color-light-blue1;
          border: 0;
        }
        @include for-phone-only {
          border-right: 0;
          hr {
            overflow: visible;
            display: block;
            background-color: $color-light-blue1;
            border: 0;
            height: 1px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
      .boxtitle {
        font-size: 11px;
        opacity: 0.9;
        @include for-phone-only {
          font-size: 10px;
        }
      }
      .boxcontent {
        font-size: 16px;
        font-weight: 800;
        @include for-phone-only {
          font-size: 14px;
        }
      }
    }
  }
  #progress {
    color: $color-grey3;
    ul {
      text-align: left;
      padding: 0 10px;
    }
    img {
      margin-top: -3px;
    }
    .borderdot {
      border-left: 1px dotted #1aca4e;
      padding-bottom: 25px;
    }
    .borderdot1 {
      border-left: 1px dotted #cccccc;
      padding-bottom: 25px;
    }
    .vicon {
      margin-left: -8px;
      font-size: 12px;
      display: flex;
      justify-content: center;
    }
    .vicon1 {
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 8px;
      display: inline-block;
      border: 5px solid #1aca4e;
    }
    .vicon2 {
      width: 16px;
      height: 16px;
      background-color: #cccccc;
      border-radius: 8px;
      display: inline-block;
      border: 1px solid #f6f7fb;
    }
  }
  .modal-content {
    padding: 24px 30px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    width: 340px;
    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 30px) !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: calc(100% - 0px) !important;
      bottom: 0 !important;
      position: absolute;
      border-radius: 20px;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .choose-acc-text {
      letter-spacing: -0.14px;
      color: #7c7c8a;
      opacity: 1;
      font-size: 14px;
      padding-bottom: 34px;
    }
    .profile-image {
      padding-right: 12px;
    }
    .g-profile {
      padding-bottom: 24px;
    }
    .name {
      letter-spacing: -0.13px;
      color: $color-black;
      font-weight: 800;
      font-size: 13px;
    }
    .email {
      font-size: 11px;
      letter-spacing: -0.33px;
      color: #7c7c8a;
    }
    .add-account {
      border-top: 1px solid #c2e2f2;
      border-bottom: 1px solid #c2e2f2;
      padding: 18px 0px;
    }
    .note {
      letter-spacing: -0.33px;
      color: #7c7c8a;
      opacity: 1;
      font-size: 11px;
      padding-top: 24px;
    }
  }
}

// Switch Funds Modal Stylings Starts

.modal-switch-funds {
  .heading-table {
    h2 {
      font-weight: 600;
    }
    p {
      font-size: 12px;
      margin-bottom: 0px;
    }
  }
  .modal-header {
    border: 0px;
    padding: 20px;
  }
  .modal-content {
    border: 1px solid #c2e2f2;
    border-radius: 10px;
  }
  .modal-body {
    padding: 20px;
  }
  .modal-footer {
    border: 0px;
    padding: 20px;
    .row-bottom {
      width: 100%;
      align-items: center;
    }
    .warning-text {
      font-size: 12px;
      color: #db3333;
      display: flex;
      align-items: center;
      column-gap: 6px;
    }
    .btn-div {
      text-align: center;
      margin: 0px auto 20px;
    }
    .btns-div {
      text-align: right;
    }
  }
}

.modal-success {
  overflow-y: auto;
  .modal-header {
    .icon-modal {
      margin: 5px 0px 15px;
    }
  }
  .modal-footer {
    .btns-div {
      display: flex;
      justify-content: center;
    }
  }
  .table {
    tbody {
      td {
        padding: 10px 0px;
        font-size: 12px;
        color: #7c7c8a;
        strong {
          color: #000000bc;
          font-size: 14px;
        }
      }
    }
  }
}

.modal-success-fp {
  font-family: "Nunito Sans" !important;
  overflow-y: auto;
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      margin-top: auto;
      margin-bottom: auto;
      align-items: end;
      min-height: 100vh;
      max-width: 100%;
    }
    h2 {
      font-size: 24px;
      font-weight: 600;
      padding-top: 15px;
    }
    .choose-acc-text {
      font-size: 16px !important;
    }
  }
  .card {
    &.card-white {
      padding: 20px;
      color: $color-grey3;
      box-shadow: none;
      .col-4 {
        @include for-phone-only {
          padding-right: 0;
          letter-spacing: -0.3px;
        }
      }
      .col-md-2 {
        white-space: nowrap;
      }
      .divider {
        border-right: 1px solid $color-light-blue1;
        margin-right: 15px;
        hr {
          overflow: hidden;
          display: none;
          background-color: $color-light-blue1;
          border: 0;
        }
        @include for-phone-only {
          border-right: 0;
          hr {
            overflow: visible;
            display: block;
            background-color: $color-light-blue1;
            border: 0;
            height: 1px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
      .boxtitle {
        font-size: 11px;
        opacity: 0.9;
        @include for-phone-only {
          font-size: 10px;
        }
      }
      .boxcontent {
        font-size: 16px;
        font-weight: 800;
        @include for-phone-only {
          font-size: 14px;
        }
      }
    }
  }
  #progress {
    color: $color-grey3;
    ul {
      text-align: left;
      padding: 0 10px;
    }
    img {
      margin-top: -3px;
    }
    .borderdot {
      border-left: 1px dotted #1aca4e;
      padding-bottom: 25px;
    }
    .borderdot1 {
      border-left: 1px dotted #cccccc;
      padding-bottom: 25px;
    }
    .vicon {
      margin-left: -8px;
      font-size: 12px;
      display: flex;
      justify-content: center;
    }
    .vicon1 {
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 8px;
      display: inline-block;
      border: 5px solid #1aca4e;
    }
    .vicon2 {
      width: 16px;
      height: 16px;
      background-color: #cccccc;
      border-radius: 8px;
      display: inline-block;
      border: 1px solid #f6f7fb;
    }
  }
  .modal-content {
    padding: 24px 30px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    width: 340px;
    // (Mobile)
    @media only screen and (max-width: 575px) {
      width: 100%;
    }
    @media only screen and (max-width: 767px) {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .choose-acc-text {
      letter-spacing: -0.14px;
      color: #7c7c8a;
      opacity: 1;
      font-size: 14px;
      padding-bottom: 30px;
    }
    .profile-image {
      padding-right: 12px;
    }
    .g-profile {
      padding-bottom: 24px;
    }
    .name {
      letter-spacing: -0.13px;
      color: $color-black;
      font-weight: 800;
      font-size: 13px;
    }
    .email {
      font-size: 11px;
      letter-spacing: -0.33px;
      color: #7c7c8a;
    }
    .add-account {
      border-top: 1px solid #c2e2f2;
      border-bottom: 1px solid #c2e2f2;
      padding: 18px 0px;
    }
    .note {
      letter-spacing: -0.33px;
      color: #7c7c8a;
      opacity: 1;
      font-size: 11px;
      padding-top: 24px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .modal-switch-funds {
    .modal-footer {
      .warning-text {
        align-items: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .modal-switch-funds {
    .heading-table {
      h2 {
        font-size: 20px;
      }
    }
    .modal-dialog {
      margin-top: auto;
      margin-bottom: auto;
      align-items: end;
      min-height: 100vh;
      max-width: 100%;
    }
    .modal-content {
      margin-top: 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .modal-footer {
      padding: 0px;
      .row-bottom {
        margin: 0px;
      }
      .warning-text {
        padding: 10px 0px;
      }
      .btn-div {
        .btn1.btn-secondary {
          min-width: 145px;
          padding: 10px 35px;
        }
        .btn1.btn-primary {
          min-width: 145px;
          padding: 10px 35px;
        }
      }
      .btns-div {
        display: flex;
        padding: 15px 0px;
        border-top: 1px solid #c2e2f2;
        margin-left: -15px;
        margin-right: -15px;
        justify-content: center;
        .btn1.btn-secondary {
          min-width: 145px;
          padding: 10px 35px;
        }
        .btn1.btn-primary {
          min-width: 145px;
          padding: 10px 35px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .modal-switch-funds {
    .modal-xl {
      max-width: 728px;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .modal-switch-funds {
    .modal-xl {
      max-width: 944px;
    }
  }
}

@media (min-width: 576px) {
  .modal-switch-funds {
    .modal-sm {
      max-width: 380px;
    }
  }
}

// Switch Funds Modal Stylings Ends

// Profile Edit Modal Stylings Starts

.modal-profile-edit {
  .heading-table {
    margin-left: auto;
    margin-right: auto;
    h2 {
      font-weight: 600;
      text-align: center;
    }
  }
  .modal-header {
    border: 0px;
    padding: 30px;
  }
  .modal-content {
    border: 1px solid #c2e2f2;
  }
  .modal-body {
    padding: 30px;
  }
  .modal-footer {
    border: 0px;
    padding: 30px;
    .btn.btn-secondary,
    .btn.btn-primary {
      min-width: 145px;
      margin: 0px 5px;
    }
    .row-bottom {
      width: 100%;
      align-items: center;
    }
    .btn-div {
      text-align: center;
      margin: 20px auto 0px;
    }
    .btns-div {
      margin: 20px auto 0px;
      text-align: center;
    }
  }
}

@media (max-width: 575px) {
  .modal-profile-edit {
    .modal-header {
      padding: 20px;
    }
    .modal-body {
      padding: 20px;
    }
    .modal-footer {
      padding: 20px 20px 30px 20px;
      .btn.btn-primary {
        min-width: unset !important;
        width: calc(50% - 10px);
        padding: 8px 0px;
      }
      .btn.btn-secondary {
        min-width: unset !important;
        width: calc(50% - 10px);
        padding: 8px 0px;
      }
      .btns-div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .modal-profile-edit {
    .heading-table {
      h2 {
        font-size: 24px;
      }
    }
    .modal-footer {
      .btns-div {
        border-top: 0px;
        padding: 0px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .modal-profile-edit {
    .modal-lg {
      max-width: 728px;
    }
  }
}

@media (min-width: 992px) {
  .modal-profile-edit {
    .modal-lg {
      max-width: 755px;
    }
  }
}

// Profile Edit Modal Stylings Ends

// Policy Loans Modal Stylings Starts

@media (min-width: 992px) {
  .modal-policy-loans {
    .modal-xl {
      max-width: 956px;
    }
  }
}

// Policy Loans Modal Stylings Ends

// Payment Success Modal Stylings Starts
.modal-success-service {
  .modal-content {
    width: 472px;
  }
}

// Payment Success  Modal Stylings Ends

// Rider Details Modal Stylings Starts

.modal-rider-details {
  .modal-header {
    border: 0px;
    padding: 20px 20px 0px 20px;
    font-size: 12px;
    color: #707070;
  }
  .modal-content {
    border: 1px solid hsl(200, 65%, 85%);
    border-radius: 10px;
    @media (min-width: 481px) and (max-width: 767px) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  .modal-body {
    padding: 0px 20px;
    color: $color-grey3;
  }
  .modal-footer {
    border: 0px;
    padding: 20px;
    text-align: center;
    justify-content: center;
  }
  .modal-footer-one {
    border: 0px;
    padding: 20px;
    text-align: center;
    .smalltitle {
      margin-left: 30px;
    }
    .content {
      margin-left: 30px;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      .smalltitle {
        text-align: right;
        margin-left: 0px;
      }
      .content {
        margin-left: 0px;
      }
    }
    .popuponebuttons {
      display: flex;
      justify-content: end;
      // (Portrait)
      @media (min-width: 320px) and (max-width: 480px) {
        justify-content: center;
        padding-top: 20px;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .modal-xl {
      max-width: 728px !important;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    .modal-xl {
      max-width: 944px !important;
    }
  }

  @media (min-width: 576px) {
    .modal-rider-details {
      .modal-sm {
        max-width: 380px;
      }
    }
  }
}

// Choose Language Modal stylings
.chooseLanguage-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    .card {
      box-shadow: none;
    }
    h2 {
      font-size: 18px;
      font-weight: normal;
    }
    .customlangpad {
      padding: 5px 0px 5px 0px;
    }
    .btn-lang {
      border: 1px solid #e3eefc;
      border-radius: 5px;
      background-color: $color-white;
      width: calc(100% - 10px);
      display: inline-block;
      padding-top: 15px;
      padding-bottom: 15px;
      &:hover {
        background-color: $color-light-blue2;
        font-weight: 600;
      }
    }
  }
  .modal-dialog-centered {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    padding: 24px 30px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    min-width: 727px;
    @include for-phone-only {
      min-width: 100%;
      bottom: 0 !important;
      position: absolute;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: 100% !important;
    }
  }
}

// Result modal stylings
.result-modal {
  font-family: "Nunito Sans" !important;
  .modal-dialog {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 24px;
      font-weight: 600;
      padding-top: 15px;
    }
    .result-small-text {
      font-size: 14px !important;
    }
  }
  .card {
    width: 180px;
    margin: 30px auto;
    &.card-light-blue {
      padding: 15px;
      color: $color-black;
      background-color: $color-light-blue2;
      box-shadow: none;
      font-size: 18px;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
  .modal-content {
    padding: 24px 30px;
    background: $color-white;
    box-shadow: 6px 6px 30px #529aff29;
    border: 1px solid #c2e2f2;
    border-radius: 10px;
    width: 340px;
    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 30px) !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      width: calc(100% - 0px) !important;
      bottom: 0 !important;
      position: absolute;
      border-radius: 20px;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .result-big-text {
      font-size: 16px !important;
      font-weight: 800;
      color: $color-black;
      letter-spacing: -0.6px;
      padding-bottom: 10px;
    }
  }
}

// Modal Upload Stylings Starts
@media (min-width: 992px) {
  .modal-upload {
    .modal-dialog {
      max-width: 604px;
    }
  }
}

// Modal Upload Stylings Ends

// Modal Bottom to top Css

.modal.fade .modal-dialog {
  transform: translate3d(0, 5%, 0);
}

.modal.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}
