.tab {
    .nav-item {
        width: 50%;
        border: none !important;
        .nav-link {
            border: none !important;
            border-bottom: 1px solid #9a9aad !important;
            color: #9a9aad !important;
            font-weight: normal;
            font-size: 10px;
            text-transform: uppercase;
            padding-bottom: 10px;
            height: 32px;
            background: transparent;
            text-align: center;
            letter-spacing: 0.6px;
            &.active {
                border: none;
                color: #0a4ca2 !important;
                border-bottom: 3px solid #0a4ca2 !important;
                font-weight: bold;
            }
        }
    }
}

// toggle tab
.toggle-tab {
    width: 200px;
    background: #e3eefc 0% 0% no-repeat padding-box;
    border: 1px solid #dde9f5;
    border-radius: 50px;
    height: 35px;
    font-family: "Lexend" !important;
    .nav-item {
        width: 50%;
        .nav-link {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            color: #929cad;
            &:hover {
                border-color: transparent !important;
            }
            &.active {
                background: transparent linear-gradient(90deg, #7d7d8c 0%, #4d4d57 100%) 0% 0% no-repeat padding-box;
                border-radius: 50px;
                color: $color-white;
            }
        }
    }
}

// toggle tab
.toggle-tab-small {
    width:60px;
    background: #e3eefc 0% 0% no-repeat padding-box;
    border: 1px solid #dde9f5;
    border-radius:15px;
    height:30px;
    font-family: "Lexend" !important;
    .nav-item {
        width: 50%;
        .nav-link {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            color: #929cad;
            &:hover {
                border-color: transparent !important;
            }
            &.active {
                background: transparent linear-gradient(90deg, #074aa3 0%, #074aa3 100%) 0% 0% no-repeat padding-box;
                border-radius: 50px;
                color: $color-white;
            }
        }
    }
}