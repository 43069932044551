.title-big {
  h2.light {
    font-size: 36px;
  }
}

.details-right-sec {
  padding-top: 5px;
  padding-bottom: 5px;
}

.profile-details-card {
  .card.card-white {
    padding: 0px;
    .boxcontent {
      font-weight: 700;
    }
  }
  @media only screen and (min-width: 992px) {
    .padding-custom-right {
      padding-right: 0px;
    }
    .padding-custom-left {
      padding-left: 0px;
    }
  }
  .person-img-div {
    position: relative;
    display: inline-block;
    .person-img-tag {
      width: 106px;
      height: 106px;
      border-radius: 150px;
      object-fit: cover;
      object-position: center;
    }
    .upload-img-div {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 7px;
      width: 24px;
      height: 24px;
      .btn {
        z-index: 9;
        padding: 0px;
      }
      label {
        z-index: 9;
        cursor: pointer;
        margin: 0px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .person-details-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.row-person-img {
  align-items: center;
}

.person-name {
  font-size: 18px;
  font-weight: 700;
  font-family: "Nunito Sans";
}

.account-toggle {
  .rtoggle-sec {
    padding: 16px;
    .rtoggle-heading {
      h5 {
        font-size: 11px;
        font-weight: 600;
        .img-icon {
          margin-bottom: 2px;
          margin-right: 4px;
        }
      }
    }
  }
  .card.card-white {
    box-shadow: none;
  }
}

.profile-details-list {
  .top-section {
    h2.light {
      font-size: 21px;
      padding-bottom: 10px;
    }
    hr {
      background-color: #c2e2f2;
    }
  }
  .content-sec {
    .moredetails-content {
      letter-spacing: -0.28px;
    }
  }
  .card.card-white {
    box-shadow: none;
  }
}

.custom-card-height {
  min-height: 95px;
}

.section-invite {
  padding: 17px 35px;
  margin-top: 50px;
  background-color: #387064;
  background: transparent linear-gradient(180deg, #50b58b 0%, #387064 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #50b58b99;
  color: #fff;
  border-radius: 10px;
  .img-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .form-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px 0px;
    .form-head {
      p {
        font-size: 16px;
      }
    }
    .form-group {
      .form-control {
        color: #fff;
        background-color: #3b7768 !important;
        border: 1px solid #50b58b !important;
        &:hover,
        &:focus {
          box-shadow: 6px 6px 30px #50b48b99;
        }
      }
      label {
        background-color: transparent;
        color: #fff;
        // opacity: 0.3;
      }
      .form-control:focus ~ label {
        color: #d2d2d9;
        // opacity: 1;
      }
    }
    .btn {
      &.btn-invite {
        min-width: 150px;
        color: #fff;
        border: 1px solid #fff;
        background: transparent linear-gradient(90deg, #50b58b 0%, #387064 100%)
          0% 0% no-repeat padding-box;
        &:hover,
        &:active,
        &:focus {
          background: transparent
            linear-gradient(90deg, #387064 0%, #50b58b 100%) 0% 0% no-repeat
            padding-box;
          //   box-shadow: 6px 6px 20px #50b58b99;
        }
      }
    }
  }
}

.social-links-div {
  display: flex;
  column-gap: 20px;
  justify-content: end;
}

.edit-icon-div {
  position: absolute;
  right: 16px;
  top: 20px;
  font-family: "Lexend";
  font-size: 10px;
}

.edit-icon-inside {
  top: 0px;
}

.edit-icon {
  cursor: pointer;
  width: max-content;
  color: #0086c1;
  img {
    margin-bottom: 3px;
  }
  &:hover {
    color: #0086c1;
  }
}

.display-in-mobile {
  display: none;
}

.form-sec-div {
  .sec-header {
    color: #7c7c8a;
    font-size: 11px;
    margin-bottom: 6px;
  }
  .row-margin {
    margin-right: -5px;
    margin-left: -5px;
    .col-padding {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .form-control:focus ~ label {
    display: none;
  }
  .form-control:valid ~ label {
    display: none;
  }
}

.form-row-padding {
  margin-left: -25px;
  margin-right: -25px;
  .form-column-padding {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.form-left-sec {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.br-line {
  border-right: 1px solid #b9ddf0;
  margin-bottom: 22px;
}

.sec-heading-div {
  text-align: center;
  h4 {
    font-family: "Lexend";
  }
  p {
    font-size: 12px;
  }
}

.upload-box {
  label {
    color: #074aa3;
    font-size: 12px;
    font-family: "Lexend";
    font-weight: 600;
    cursor: pointer;
    border: 1px dashed #b9ddf0;
    height: 160px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    border-radius: 4px;
  }
  &.height-change {
    label {
      height: 87px;
    }
  }
}

.take-picture {
  display: none;
  margin-top: 20px;
}

.heading-sm {
  display: none;
}

.divider-line {
  display: none;
}

.uploaded-file-div {
  font-size: 12px;
  height: 87px;
  text-align: center;
  color: #074aa3;
  border-radius: 6px;
  background-color: #e3eefc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .uploaded-file {
    display: flex;
    align-items: center;
  }
  .reupload-btn {
    padding: 0px;
    font-size: 12px !important;
    font-weight: 600;
    color: #074aa3;
    font-family: 'Lexend' !important;
  }
  .btn {
    padding: 0px;
    font-size: 12px !important;
    font-weight: 600;
    color: #074aa3;
  }
}

@media only screen and (max-width: 575px) {
  .profile-details-list {
    .top-section {
      .sec-header {
        font-size: 10px;
      }
      .moredetails-content {
        font-size: 12px;
      }
    }
  }
  .account-toggle {
    .card.card-white {
      margin-top: 10px;
    }
    .rtoggle-sec {
      .rtoggle-heading {
        h5 {
          font-size: 10px;
          // width: max-content;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  // .email-id-div {
  //   display: -webkit-box;
  //   -webkit-line-clamp: 1;
  //   -webkit-box-orient: vertical;
  //   overflow: hidden;
  // }
  .br-line {
    border-right: 0px;
  }
  .divider-line {
    display: block;
    border-top: 1px solid #b9ddf0;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .profile-details-list {
    .top-section {
      .moredetails-content {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .heading-sm {
    display: block;
  }
  .heading-md {
    display: none;
  }
  .take-picture {
    display: block;
  }
  .title-big h2.light {
    padding-bottom: 5px;
  }
  .display-in-mobile {
    display: block;
  }
  .show-in-desk {
    display: none;
  }
  .profile-details-list {
    .card.card-white {
      .card-body {
        padding: 0.5rem 1.25rem;
      }
    }
    .content-sec {
      margin: 10px 0px;
    }
  }
  .row-person-img {
    margin-bottom: 10px;
    .person-name {
      margin-bottom: 0px;
      font-size: 16px;
    }
  }
  .row-person-details {
    .content-sec {
      margin-top: 20px;
    }
  }
  .profile-details-card {
    // position: relative;
    // &:before {
    //   content: "";
    //   box-shadow: 1px -3px 1px #958a81;
    //   display: block;
    //   position: absolute;
    //   top: 0px;
    //   left: 0px;
    //   height: 105px;
    //   width: 105px;
    //   border-radius: 10px;
    // }
    .padding-custom-left {
      padding-left: 15px;
    }
    .padding-custom-right {
      padding-right: 15px;
    }
  }
  .edit-icon-div {
    .span-text {
      display: none;
    }
  }
  .section-invite {
    padding: 20px;
    .img-left {
      img {
        width: 100%;
      }
    }
    .form-right {
      padding: 0px;
      .form-head {
        h1 {
          font-size: 20px;
          margin-top: 30px;
          margin-bottom: 25px;
        }
        p {
          font-size: 14px;
          margin-bottom: 35px;
        }
      }
      .btn.btn-invite {
        display: block;
        margin-top: 8px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
  .social-links-div {
    margin-top: 30px;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .profile-details-card {
    .padding-custom-left {
      padding-left: 25px;
    }
    .card.card-white {
      .boxcontent {
        font-size: 14px;
      }
    }
  }
  .profile-details-list {
    .content-sec {
      .moredetails-content {
        font-size: 12px;
      }
    }
  }

  .section-invite {
    .img-left {
      img {
        width: 100%;
      }
    }
    .form-right {
      .form-head {
        h1 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
  .social-links-div {
    column-gap: 10px;
  }
}

// Overlay Effect Starts

.picture-view {
  position: relative;
  height: 260px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

.overlay-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  overflow: hidden;
}

.overlay-left {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  width: 210px;
  height: 210px;
  // overflow: hidden;
}

.overlay-right {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  width: 210px;
  height: 210px;
  // overflow: hidden;
}

.overlay-left,
.overlay-right {
  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
  }
}

.overlay-left {
  &:before {
    top: 0;
    left: 0;
    box-shadow: -105px -105px 0 105px #000;
  }
  &:after {
    bottom: 0;
    left: 0;
    box-shadow: -105px 105px 0 105px #000;
  }
}

.overlay-right {
  &:before {
    top: 0;
    right: 0;
    box-shadow: 105px -105px 0 105px #000;
  }
  &:after {
    bottom: 0;
    right: 0;
    box-shadow: 105px 105px 0 105px #000;
  }
}

// Overlay Effect Ends

.content-description {
  color: #707070;
  font-size: 12px;
}

#myBtn {
  color: $color-primary;
  cursor: pointer;
}

.upload-box-three {
  label {
    margin: auto;
    @media (min-width: 576px) {
      width: 316px;
    }
  }
}

.uploaded-file-div-three {
  margin: auto;
  @media (min-width: 576px) {
    width: 316px;
  }
}