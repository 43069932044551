.btn {
  font-size: 12px;
  padding: 8px 18px;
  border-radius: 50px;
  font-family: "Lexend" !important;
  &.btn-primary {
    min-width: 160px;
    background: transparent linear-gradient(90deg, #0b86c1 0%, #074aa3 100%) 0%
      0% no-repeat padding-box;
    border: none !important;
    font-size: 12px;
    font-weight: 600;
    padding: 9px 18px;
    &:hover,
    &:active,
    &:focus {
      background: transparent linear-gradient(90deg, #074aa3 0%, #0b86c1 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #0b86c1 !important;
      // font-weight: normal !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      min-width: 130px !important;
    }
    &.disabled,
    &:disabled {
      cursor: unset;
      background: transparent linear-gradient(90deg, #0b86c1 0%, #074aa3 100%)
        0% 0% no-repeat padding-box;
      &:hover,
      &:focus,
      &:active {
        box-shadow: none !important;
      }
    }
  }
  &.btn-secondary {
    min-width: 160px;
    color: $color-primary;
    background: transparent linear-gradient(90deg, #ffffff 0%, #e3eefc 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid $color-primary;
    font-weight: 600;
    &:hover,
    &:active,
    &:focus {
      background: transparent linear-gradient(90deg, #e3eefc 0%, #ffffff 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 5px #0b86c1 !important;
      border: 1px solid $color-primary !important;
      // font-weight: normal;
      color: $color-primary !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      min-width: 130px !important;
    }
    &.disabled,
    &:disabled {
      background: transparent linear-gradient(90deg, #ffffff 0%, #e3eefc 100%)
        0% 0% no-repeat padding-box;
        cursor: unset;
      &:hover,
      &:focus,
      &:active {
        box-shadow: none !important;
      }
    }
  }
  &.btn-tertiary {
    min-width: 160px;
    color: $color-primary;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus {
      background: $color-light-blue2 !important;
      box-shadow: none !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      min-width: 130px !important;
    }
  }
  &.btn-carousel {
    color: $color-white;
    border: 1px solid $color-white;
    min-width: 160px;
    &.red {
      border: solid 1px #fff;
      background-image: linear-gradient(to right, #ef6f82 13%, #b15462 88%);
      &:hover,
      &:active,
      &:focus {
        box-shadow: 0 0 6px 0 #ef4e64;
        border: solid 1px #fff;
        background-image: linear-gradient(to right, #b15462 11%, #ef6f82 92%);
      }
    }
    &.yellow {
      background: transparent linear-gradient(90deg, #d8b956 0%, #b19745 100%)
        0% 0% no-repeat padding-box;
      border: 1px solid $color-white;
      &:hover,
      &:active,
      &:focus {
        background: transparent linear-gradient(90deg, #b19745 0%, #d8b956 100%)
          0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #d8b956;
        border: 1px solid $color-white;
      }
    }
  }
  &.btn-circle {
    background: $color-white;
    box-shadow: 6px 6px 20px #0a4ca24d;
    border: 1px solid $color-light-blue1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    min-width: 65px !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0px 0px 20px #0b86c1cc;
      border: 1px solid $color-primary;
      .not-hover-state {
        display: none !important;
      }
      .hover-state {
        display: flex !important;
      }
    }
    &.blue {
      background: $gradient-blue;
      border: $color-primary;
    }
  }
  &.btn-social-media {
    border: 1px solid $color-grey1;
    width: 100%;
    border-radius: 50px;
    font-size: 11px;
    color: $color-grey3;
    padding: 8px;
    background: transparent linear-gradient(90deg, #ffffff 0%, #e3eefc 100%) 0%
      0% no-repeat padding-box;
    &:hover,
    &:active,
    &:focus {
      background: transparent linear-gradient(90deg, #e3eefc 0%, #ffffff 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 5px #0b86c1;
      // font-weight: normal;
    }
  }
}

.btn1 {
  font-size: 12px;
  padding: 8px 18px;
  border-radius: 50px;
  font-family: "Lexend" !important;
  @include for-phone-only {
    padding: 10px;
  }
  &.btn-primary {
    min-width: 160px;
    background: transparent linear-gradient(90deg, #0b86c1 0%, #074aa3 100%) 0%
      0% no-repeat padding-box;
    border: none !important;
    font-size: 12px;
    font-weight: 600;
    &:hover,
    &:active,
    &:focus {
      background: transparent linear-gradient(90deg, #074aa3 0%, #0b86c1 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #0b86c1 !important;
      // font-weight: normal !important;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      min-width: 100px !important;
    }
    @include for-phone-only {
      min-width: 145px;
    }
  }
  &.btn-secondary {
    min-width: 160px;
    color: $color-primary;
    background: transparent linear-gradient(90deg, #ffffff 0%, #e3eefc 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid $color-primary;
    font-weight: 600;
    margin-right: 10px;
    &:hover,
    &:active,
    &:focus {
      background: transparent linear-gradient(90deg, #e3eefc 0%, #ffffff 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 5px #0b86c1 !important;
      border: 1px solid $color-primary !important;
      // font-weight: normal;
      color: $color-primary !important;
    }
    // (Portrait)
    @media (min-width: 320px) and (max-width: 480px) {
      min-width: 100px !important;
    }
    @include for-phone-only {
      min-width: 145px;
    }
  }
}


