.overview-section {
    padding: 30px 0px 30px 34px;
    // min-height: calc(100vh - 390px);
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        padding: 30px 20px 30px 20px;
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        padding: 30px 20px 30px 20px;
    }
    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 30px 20px 30px 20px;
    }
    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
        padding: 30px 20px 30px 20px;
    }
    .page-section {
        padding-right: 34px;
        @media only screen and (min-width: 1366px) {
            width: 1040px;
            padding-right: 0px;
        }
        @media only screen and (min-width: 1919px) {
            width: 1440px;
            padding-left: 160px;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            padding-right: 0px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            padding-right: 0px;
        }
        @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-right: 0px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-right: 0px;
        }
    }
    .pt-50 {
        padding-top: 50px;
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-top: 44px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            padding-top: 44px;
        }
    }
    h2.light {
        color: $color-grey2;
        padding-bottom: 14px;
        margin-bottom: 0px;
        @media only screen and (max-width: 575px) {
            font-size: 20px;
            padding-bottom: 16px;
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            font-size: 21px !important;
            padding-bottom: 16px;
        }
        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            font-size: 21px !important;
            padding-bottom: 16px;
        }
    }
    .imp-updates {
        .due-date-text {
            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                font-size: 13px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                font-size: 13px;
            }
        }
        .padding-r {
            padding-right: 20px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                padding-right: 4px !important;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                padding-right: 4px !important;
            }
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                padding-right: 10px !important;
            }
            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding-right: 4px !important;
                width: 50% !important;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding-right: 4px !important;
                width: 50% !important;
            }
        }
        .imp-updates-btn {
            min-width: 144px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                min-width: 130px;
                padding: 8px 10px;
                margin-left: -26px;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                min-width: 130px;
                padding: 8px 10px;
                margin-left: -26px;
            }
        }
        .card {
            padding: 16px 20px;
            margin-bottom: 8px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                padding: 16px 16px;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                padding: 16px 16px;
            }
            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 20px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 20px;
            }
            .insurance-name-sec {
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-left: 0px;
                    display: flex !important;
                    align-items: flex-start !important;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-left: 0px;
                    display: flex !important;
                    align-items: flex-start !important;
                }
            }
            .btn-sec {
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-top: 20px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-top: 20px;
                }
            }
            .due-info {
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    padding-right: 0px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    padding-right: 0px;
                }
                @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-bottom: 16px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-bottom: 16px;
                }
                .body-text {
                    color: #737896;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        width: calc(100% - 10px);
                    }
                    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                        width: calc(100% - 10px);
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        white-space: unset !important;
                        overflow: unset !important;
                        text-overflow: unset !important;
                        width: auto !important;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        width: calc(100% - 10px);
                        white-space: unset !important;
                        overflow: unset !important;
                        text-overflow: unset !important;
                        width: auto !important;
                    }
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        white-space: unset !important;
                        overflow: unset !important;
                        text-overflow: unset !important;
                        width: auto !important;
                        font-size: 10px;
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        white-space: unset !important;
                        overflow: unset !important;
                        text-overflow: unset !important;
                        width: auto !important;
                        font-size: 10px;
                    }
                }
            }
            .due-date-heading {
                color: $color-grey2;
                padding-bottom: 4px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    padding-bottom: 0px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    padding-bottom: 0px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                    padding-bottom: 1px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                    padding-bottom: 1px;
                }
            }
            .due-date {
                font-size: 14px;
                color: $color-blue;
                font-weight: 800;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    font-size: 13px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    font-size: 13px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 12px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 12px;
                }
            }
            .insurance-name {
                font-size: 13px;
                color: $color-grey3;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    font-size: 12px;
                    white-space: unset !important;
                    overflow: unset !important;
                    text-overflow: unset !important;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    font-size: 12px;
                    white-space: unset !important;
                    overflow: unset !important;
                    text-overflow: unset !important;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                    white-space: unset !important;
                    overflow: unset !important;
                    text-overflow: unset !important;
                    line-height: 1.6;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                    white-space: unset !important;
                    overflow: unset !important;
                    text-overflow: unset !important;
                    line-height: 1.6;
                }
            }
            .date-section {
                padding-left: 40px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    padding-left: 20px;
                    padding-right: 0px;
                    align-items: center;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    padding-left: 20px;
                    padding-right: 0px;
                    align-items: center;
                }
                @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-left: 15px;
                    padding-right: 0px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-left: 15px;
                }
                .vr-line {
                    height: 100%;
                    width: 1px;
                    background-color: $color-border;
                    position: absolute;
                    left: 12px;
                    @include for-phone-only {    
                        height:1px;
                        width:100%;
                        left:0;
                        position:relative;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        left: 5px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        left: 5px;
                    }
                    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: none;
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }
    .consolidated-stats {
        font-family: "Nunito Sans" !important;
        .card {
            padding: 20px 40px 20px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                padding: 20px 30px 20px;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                padding: 20px 30px 20px;
            }
            @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 20px 20px 20px;
            }
            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                padding: 20px 20px 20px;
            }
            .sum-section {
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    padding-right: 0px !important;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    padding-right: 0px !important;
                }
                @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                    padding-right: 0px !important;
                }
                @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    margin-bottom: 20px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    margin-bottom: 20px;
                }
            }
            .total-sum {
                color: $color-white;
                opacity: 0.7;
                font-weight: normal;
                font-family: "Nunito Sans" !important;
                margin-bottom: 0;
                padding-top: 12px;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-top: 0px;
                    padding-bottom: 5px;
                    font-size: 12px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-top: 0px;
                    padding-bottom: 5px;
                    font-size: 12px;
                }
            }
            .num1 {
                color: $color-white;
                font-weight: bold;
                border-bottom: 1px solid #359ccc;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    font-size: 32px;
                    padding-bottom: 10px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    font-size: 32px !important;
                    padding-bottom: 10px !important;
                }
                @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                    font-size: 42px;
                    padding-bottom: 10px;
                }
                @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 28px;
                    padding-bottom: 10px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 28px;
                    padding-bottom: 10px;
                }
            }
            .number-of-policies {
                padding-top: 14px;
                font-size: 12px;
                color: $color-white;
                opacity: 0.7;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    position: absolute;
                    top: 0;
                    right: 20px;
                    font-size: 10px;
                    padding-top: 0px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    position: absolute;
                    top: 0;
                    right: 20px;
                    font-size: 10px;
                }
            }
            .amount-heading {
                font-size: 10px;
                color: $color-white;
                opacity: 0.7;
                padding-top: 10px;
                margin-bottom: 2px;
            }
            .num3 {
                color: $color-white;
                font-weight: bold;
                &.blue-card-num {
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        display: none !important;
                        &.ipad-p {
                            display: block !important;
                            font-size: 22px;
                        }
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        display: none !important;
                        &.ipad-p {
                            display: block !important;
                            font-size: 22px;
                        }
                    }
                    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: none !important;
                        &.ipad-p {
                            display: block !important;
                            font-size: 18px;
                        }
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: none !important;
                        &.ipad-p {
                            display: block !important;
                            font-size: 18px;
                        }
                    }
                }
            }
            .btn-secondary {
                padding: 8px 15px;
                margin-top: 28px;
            }
            .righteous-amount-block {
                position: relative;
                .righteous-amount {
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        position: absolute;
                        bottom: 21px;
                        right: 28px;
                        z-index: 1;
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        position: absolute;
                        bottom: 21px;
                        right: 28px;
                        z-index: 1;
                    }
                }
            }
            .progress-section {
                .vr-line {
                    height: 100%;
                    width: 1px;
                    background-color: #359ccc;
                    position: absolute;
                    left: 24px;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        left: 16px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        left: 16px;
                    }
                    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: none;
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }
    .handpicked-pre-Approved {
        .card {
            .details-lable {
                font-size: 11px;
                color: $color-grey3;
                font-weight: normal;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                }
            }
            .details-name {
                font-size: 16px;
                color: $color-grey3;
                font-weight:800;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 12px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 12px;
                }
            }
            .btn-carousel {
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    position: absolute;
                    bottom: 16px;
                    left: 16px;
                    z-index: 1;
                    min-width: 120px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    position: absolute;
                    bottom: 16px;
                    left: 16px;
                    z-index: 1;
                    min-width: 120px;
                }
            }
            .carousel-heading {
                font-size: 30px;
                font-weight: 600;
                color: $color-white;
                letter-spacing: -0.9px;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 20px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 20px;
                }
            }
            .name {
                color: $color-white;
                font-size: 16px;
                font-weight: normal;
                padding: 10px 0px 110px;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 14px;
                    padding: 10px 0px 150px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 14px;
                    padding: 10px 0px 150px;
                }
            }
            .carousel-image {
                width: auto !important;
                position: absolute;
                bottom: 0;
                right: 0;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    width: 300px !important;
                    height: 242px !important;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    width: 300px !important;
                    height: 242px !important;
                }
                @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
                    width: 300px !important;
                    height: 242px !important;
                }
                @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    width: 188px;
                    height: 174px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    width: 188px;
                    height: 174px;
                }
            }
        }
    }
    .active-policies {
        .card {
            font-family: "Nunito Sans", sans-serif;
            .insurance-name {
                font-size: 18px;
                color: $color-black;
                font-weight: normal;
                padding-bottom: 5px;
                font-family: "Lexend" !important;
                @include for-phone-only {    
                    font-size: 16px; 
                 }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    font-size: 16px;
                    letter-spacing: -0.48px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    font-size: 16px;
                    letter-spacing: -0.48px;
                }
                @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    white-space: unset !important;
                    overflow: unset !important;
                    text-overflow: unset !important;
                    width: unset !important;
                    padding-bottom: 12px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    white-space: nowrap;
                    overflow: unset !important;
                    text-overflow: unset !important;
                    width: unset !important;
                    padding-bottom: 12px;
                }
            }
            .plan-name {
                font-size: 14px;
                color: $color-grey2;
                font-weight: normal;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 12px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 12px;
                }
            }
            .sum-section {
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-top: 30px;
                    justify-content: space-between;
                    .first-sec {
                        padding: 0px 4px 0px 0px !important;
                    }
                    .second-sec {
                        padding: 0px 4px 0px 4px !important;
                    }
                    .third-sec {
                        padding: 0px 0px 0px 4px !important;
                    }
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding-top: 30px;
                    justify-content: space-between;
                    .first-sec {
                        padding: 0px 4px 0px 0px !important;
                    }
                    .second-sec {
                        padding: 0px 4px 0px 4px !important;
                    }
                    .third-sec {
                        padding: 0px 0px 0px 4px !important;
                    }
                }
            }
            .policy-number-heading,
            .policy-date-heading {
                font-size: 12px;
                color: $color-grey2;
                font-weight: normal;
                padding-bottom: 4px;
                @include for-phone-only {    
                    font-size:10px; 
                 }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    font-size: 10px;
                    letter-spacing: -0.3px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    font-size: 10px;
                    letter-spacing: -0.3px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                    letter-spacing: -0.3px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 10px;
                    letter-spacing: -0.3px;
                }
            }
            .policy-number {
                font-size: 18px;
                color: $color-blue;
                font-weight: 800;
                letter-spacing: -0.18px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    display: none !important;
                    &.ipad-p {
                        display: block !important;
                        font-size: 16px;
                    }
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    display: none !important;
                    &.ipad-p {
                        display: block !important;
                        font-size: 16px;
                    }
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    display: none !important;
                    &.ipad-p {
                        display: block !important;
                        font-size: 16px;
                    }
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    display: none !important;
                    &.ipad-p {
                        display: block !important;
                        font-size: 16px;
                    }
                }
            }
            .policy-date {
                font-size: 18px;
                color: $color-blue;
                font-weight: 800;
                letter-spacing: -0.18px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    font-size: 16px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    font-size: 16px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 16px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    font-size: 16px;
                }
            }
            .border-right {
                border-right: 1px solid $color-border !important;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    border-right: 0px !important;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    border-right: 0px !important;
                }
            }
            .border-left {
                border-left: 1px solid $color-border !important;
            }
            .card-body {
                padding: 30px;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 20px;
                }
                @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 20px;
                }
                .top-section {
                    .col-2 {
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            padding: 14px;
                        }
                        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                            padding: 14px;
                        }
                    }
                }
                .sec-header {
                    font-size: 12px;
                    color: #7d7d8c;
                    padding-bottom: 4px;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        font-size: 10px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        font-size: 10px;
                    }
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 10px;
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 10px;
                    }
                }
                .content {
                    font-size: 16px;
                    color: $color-grey3;
                    font-weight: 800;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        font-size: 14px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        font-size: 14px;
                    }
                    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        padding-bottom: 20px;
                        font-size: 14px;
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        padding-bottom: 20px;
                        font-size: 14px;
                    }
                }
                .complete-sec {
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        display: unset !important;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        display: unset !important;
                    }
                    @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: unset !important;
                    }
                    @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: unset !important;
                    }
                }
                .inside-card {
                    border: 1px solid $color-border;
                    border-radius: 10px;
                    padding: 15px 20px 15px 20px;
                    .bar-chart {
                        padding-left:20px;
                        margin-top: -26px;
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            margin-top: -22px;
                            padding-left: 10px;
                        }
                        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                            margin-top: -22px;
                            padding-left: 10px;
                        }
                        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                            padding-left: 0px;
                            margin-top: 40px;
                        }
                        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                            margin-top: -22px;
                        }
                    }
                    .absolute-return {
                        letter-spacing: 0.6px;
                        color: $color-grey1;
                        text-transform: uppercase;
                        font-size: 10px;
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            font-size: 10px;
                        }
                        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                            font-size: 10px;
                        }
                        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                            font-size: 10px;
                        }
                        @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                            font-size: 10px;
                        }
                        &.fund-performance {
                            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                                width: 49%;
                                font-size: 10px;
                            }
                            @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                                font-size: 10px;
                            }
                        }
                    }
                    .absolute-return-val {
                        letter-spacing: -0.72px;
                        color: #1acd51;
                        font-size: 24px;
                        font-weight: 700;
                        padding-top: 6px;
                    }
                    .label {
                        color: $color-grey1;
                        font-size: 10px;
                        padding-left:20px;
                        padding-top: 4px;
                        width: 110px;
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            padding-left: 10px;
                            width: 100px;
                        }
                        // @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        //     padding-left: 10px;
                        //     width: 100px;
                        // }
                    }
                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        position: absolute;
                        left: 22px;
                        margin-top: 3px;
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            left: 10px !important;
                            margin-top: 2px;
                        }
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            left: 10px !important;
                            margin-top: 2px;
                        }
                        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                            left: 10px !important;
                            margin-top: 2px;
                        }
                        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                            left: 0px !important;
                            margin-top: 2px;
                        }
                        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                            left: 10px !important;
                            margin-top: 2px;
                        }
                        &.green {
                            background: linear-gradient(181deg, #00d351 99%, #13a651 0%);
                        }
                        &.blue {
                            background: linear-gradient(to bottom, #fe9d00, #a66600);
                        }
                        &.grey {
                            background: linear-gradient(to bottom, #9a9aad, #4d4d57);
                        }
                    }
                    .label-section {
                        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                            width: 50%;
                            position: absolute;
                            top: 10px;
                            right: -10px;
                        }
                        // @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        //     width: 50%;
                        //     position: absolute;
                        //     top: 10px;
                        //     right: 0;
                        // }
                    }
                    &.chart-card {
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            width: 497px;
                            padding: 13px 15px 13px 15px;
                        }
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            padding: 15px 10px 15px 10px !important;
                        }
                        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                            padding: 15px 10px 15px 10px !important;
                            margin-right: 0px !important;
                        }
                        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                            padding: 15px 10px 15px 10px !important;
                            width: 100%;
                            margin-right: 0px !important;
                        }
                        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                            padding: 15px 10px 15px 10px !important;
                            width: 100%;
                            margin-right: 0px !important;
                        }
                    }
                    &.absolute-return-card {
                        min-width: 160px;
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                        }
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            padding: 15px 10px 15px 10px !important;
                        }
                        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                        }
                        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                            width:100%;
                        }
                        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                        }
                    }
                    &.crt-card {
                        min-width: 160px;
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                        }
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            padding: 15px 10px 15px 10px !important;
                        }
                        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                        }
                        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                            width: 100%;
                        }
                        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                            padding: 15px 10px 15px 10px !important;
                            margin-top: 10px;
                        }
                    }
                }
                .bottom-btn-block {
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: unset !important;
                        .primary-btn-sec {
                            display: flex !important;
                            align-items: center !important;
                            justify-content: center !important;
                            padding-top: 30px;
                            .btn-primary {
                                margin-right: 10px !important;
                                min-width: 135px !important;
                            }
                            .btn-secondary {
                                min-width: 135px !important;
                                padding: 8px 13px;
                            }
                        }
                        .sec-btn-block {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 14px;
                        }
                    }
                    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: unset !important;
                        .primary-btn-sec {
                            display: flex !important;
                            align-items: center !important;
                            justify-content: center !important;
                            padding-top: 30px;
                            .btn-primary {
                                margin-right: 10px !important;
                                min-width: 135px !important;
                            }
                            .btn-secondary {
                                min-width: 135px !important;
                            }
                        }
                        .sec-btn-block {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 14px;
                        }
                    }
                }
                .chart-inside-sec {
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        display: unset !important;
                    }
                    // @media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    //     display: unset !important;
                    // }
                }
            }
        }
    }
    .self-help-videos {
        .see-all-videos-btn {
            min-width: 130px;
        }
        .image-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            transition: 0.3s ease;
            background-color: #21252945;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .large-video {
            .preview-img {
                width: 100%;
                height: 392px;
                object-fit: cover;
                border-radius: 10px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    height: 311px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    height: 311px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    height: 230px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    height: 300px;
                }
            }
            .image-overlay {
                border-radius: 10px;
            }
            .bottom-text {
                position: absolute;
                bottom: 20px;
            }
            .bottom-text {
                position: absolute;
                bottom: 24px;
                left: 30px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    left: 16px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    left: 16px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    left: 15px;
                    bottom: 11px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    left: 15px;
                    bottom: 11px;
                }
                .main-text {
                    font-size: 24px;
                    font-weight: bold;
                    color: $color-white;
                    padding-bottom: 4px;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        font-size: 16px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        font-size: 16px;
                    }
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 14px;
                    }
                    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 14px;
                    }
                }
                .sub-text {
                    font-size: 14px;
                    font-weight: normal;
                    color: $color-white;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        font-size: 12px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        font-size: 12px;
                    }
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 10px;
                    }
                    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 10px;
                    }
                }
            }
        }
        .card {
            margin-bottom: 10px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                height: 97px;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                height: 97px;
            }
            .text-blk {
                padding: 16px;
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 13px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 13px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 10px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 10px;
                }
                .heading-text {
                    font-size: 16px;
                    color: $color-black;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        font-size: 14px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        font-size: 14px;
                    }
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 14px;
                    }
                    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 14px;
                    }
                }
                .info-text {
                    font-size: 12px;
                    color: $color-grey2;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        font-size: 10px;
                    }
                    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                        font-size: 10px;
                    }
                    @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 10px;
                    }
                    @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                        font-size: 10px;
                    }
                }
            }
            .preview-img {
                height: 122px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    height: 97px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    height: 97px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    height: 97px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    height: 97px;
                }
            }
            .image-overlay {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    height: 97px;
                }
                @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                    height: 97px;
                }
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    height: 97px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    height: 97px;
                }
            }
        }
        .row {
            @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                margin: 0px !important;
            }
            @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                margin: 0px !important;
            }
            .video-right-block {
                @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 0px !important;
                    margin-top: 20px;
                }
                @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                    padding: 0px !important;
                    margin-top: 20px;
                }
            }
        }
    }
}



@media screen and (max-width: 991px) {
    .overview-section{
        min-height: calc(100vh - 390px);
    }
}