.pay-premium-section{
    .page-section{
        padding-right: 0;
        .title{
            padding-bottom: 23px;
            font-size: 14px;
        }
        .toggleblock{
            font-size: 14px;
            font-weight: 600;
            color: $color-light-black;
            justify-content: end;
            @include for-phone-only {
                padding-top: 30px;
                text-align: center;
                justify-content: center;
             }
            .pay{
                font-weight: 800;
                color:$color-primary;
            }
        }
        h2.light{
            font-size: 24px;
            color:$color-grey;
            letter-spacing: -0.25px;
            padding-bottom: 0px;
            white-space: nowrap;
            a{
                margin-right:12px;
            }
        }
        .form-group{
            height: 45px;
        }
    }
}
.pay-premium{
    .card{
        &.card-white{
            padding:20px; 
            color: $color-grey3;
            box-shadow: none;
            .col-4{
                @include for-phone-only {
                    padding-right: 0;
                    letter-spacing: -0.3px;
                 }
            }
            .col-md-2{
                white-space: nowrap;
            }
            .divider{
                border-right: 1px solid $color-light-blue1;
                margin-right: 15px;
                hr{
                    overflow: hidden;
                    display: none;
                    background-color: $color-light-blue1;
                    border: 0;
                }
                @include for-phone-only {    
                    border-right:0;
                    hr{
                        overflow:visible;
                        display: block;
                        background-color: $color-light-blue1;
                        border: 0;
                        height: 1px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
            .boxtitle{
                font-size: 11px;
                opacity: 0.9;
                @include for-phone-only {
                    font-size: 10px;
                 }
            }
            .boxcontent{
                font-size: 16px;
                font-weight: 800;
                @include for-phone-only {
                    font-size: 14px;
                }
            }
        }
    }
    .custom-radio .custom-control-label::after {
        top: 0px;
        }
    hr{
        background-color: $color-light-blue1;
        margin: 20px 0 10px 0;
    }
    .total{
        font-size: 10px;
        .custom-checkbox{
            display: inline;
            float: right;
            margin-top: 0;
            @include for-phone-only {    
                float: left;
            }
        }
        .totaltext{
        font-size: 18px;
        color:$color-black1;
        @include for-phone-only {    
            font-size: 14px;
            padding-bottom: 30px;
         }
        }

    }
    
}

//Change Premium Style
.change-premium{
    hr{
    margin: 0px 0px 20px 0px;
    }
}