.accordion-section-div {
  .accordion-main {
    padding-bottom: 25px;
    .accordion-header {
      .btn {
        display: block;
        width: 100%;
        padding: 0px;
        .arrow-image {
          width: 15px;
          height: 15px;
          background: url(../assets/images/accordion-down.svg);
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0.5;
        }
        &[aria-expanded="true"] {
          .arrow-image {
            background: url(../assets/images/accordion-up.svg);
            background-repeat: no-repeat;
            opacity: 1;
          }
          .heading-line {
            font-weight: bold;
            color: #074aa3;
          }
        }
      }
      .heading-toggle {
        font-family: "Nunito Sans", sans-serif;
        border-bottom: 1px solid #b9ddf0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 7px;
        .heading-line {
          font-size: 16px;
          color: #4d4d5c;
        }
      }
    }
    .accordion-body {
      padding-top: 10px;
    }
  }
}

.card-form {
  .sec-header {
    font-size: 11px;
    color: #7c7c8a;
    margin-bottom: 1px;
  }
  .date-note {
    margin-top: -15px;
    margin-bottom: 0px;
  }
  .content-sec {
    .moredetails-content {
      font-size: 16px;
      font-weight: 700;
      color: #51515c;
      // overflow: hidden;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
    }
  }
  .buttons-div {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 26px;
    .btn {
      margin: 0px 5px;
      .btn-img {
        margin-left: 3px;
        margin-bottom: 2px;
      }
    }
    .btn.btn-primary,
    .btn.btn-secondary,
    .btn.btn-tertiary {
      min-width: unset;
      width: calc(50% - 10px);
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.card.card-white.card-form {
  box-shadow: none;
}

.sec-last {
  padding-top: 15px;
  .card.card-white.card-form {
    margin-bottom: 16px;
  }
  .sec-header {
    font-size: 16px;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .button-single {
    text-align: right;
  }
}

.rowtop-spacing {
  margin-top: 20px;
}

.download-btn-div {
  display: flex;
  align-items: center;
  height: 100%;
  .btn.btn-tertiary {
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
  }
}


@media (max-width: 575px) {
  .card-form {
    .content-sec {
      .sec-header {
        font-size: 10px;
      }
      .moredetails-content {
        font-size: 14px;
      }
    }
    .buttons-div {
      justify-content: space-between;
      margin-top: 0px;
      margin-right: -5px;
      margin-left: -5px;
    }
    .date-note {
      padding-bottom: 15px;
    }
  }
  .rowtop-spacing {
    margin-top: 0px;
  }
  .sec-last {
    .button-single {
      text-align: left;
      padding-top: 10px;
    }
  }
  .download-btn-div {
    .btn.btn-tertiary {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

@media (max-width: 767px) {
  .accordion-section-div {
    margin-top: 10px;
    .card-body {
      padding: 10px 15px;
    }
    .content-sec {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .card-form {
    .buttons-div {
      justify-content: center;
    }
  }
  .download-btn-div {
    .btn.btn-tertiary {
      margin-left: -19px;
      width: max-content;
      min-width: unset;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .card-form {
    .buttons-div {
      margin-top: 15px;
      .btn.btn-primary,
      .btn.btn-secondary,
      .btn.btn-tertiary {
        width: calc(100% - 10px);
      }
    }
    .first-btns-div {
      margin-top: 32px;
    }
  }
  .sec-last {
    .sec-header {
      justify-content: center;
    }
    .button-single {
      margin-top: 10px;
      text-align: center;
    }
  }

  .accordion-section-div {
    .card-body {
      padding: 10px 20px;
    }
    .content-sec {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .download-btn-div {
    .btn.btn-tertiary {
      margin-left: -19px;
      width: max-content;
      min-width: unset;
    }
  }
}
