/* Header Styles */

.header {
    display: block;
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    border-bottom: 1px solid #c2e2f2;
    background: #ffffff;
    font-family: "Lexend" !important;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        height: 60px;
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
        height: 60px !important;
    }
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        height: 60px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
        height: 60px;
    }
    &.customer-portal-header {
        height: 80px;
        background: #ffffff;
        // border-bottom: none !important;
        .custome-p-header {
            // border-bottom: 1px solid #c2e2f2;
            width: calc(100% - 240px);
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                border-bottom: none !important;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                border-bottom: none !important;
            }
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                border-bottom: none !important;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                border-bottom: none !important;
            }
        }
        .right-block {
            width: 100% !important;
        }
        .search-section {
            width: 100% !important;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            // background-image: linear-gradient(to right, #0c87c1 6%, #0a4ca2 97%);
            height: 60px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            // background-image: linear-gradient(to right, #0c87c1 6%, #0a4ca2 97%);
            height: 60px;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            // background-image: linear-gradient(to right, #0c87c1 6%, #0a4ca2 97%);
            height: 60px;
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
            // background-image: linear-gradient(to right, #0c87c1 6%, #0a4ca2 97%);
            height: 60px;
        }
        .right-block {
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                background: transparent;
                border-bottom: none;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                background: transparent;
                border-bottom: none;
            }
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                background: transparent;
                border-bottom: none;
            }
            @media screen and (orientation:portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
                background: transparent;
                border-bottom: none;
            }
            @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
                background: transparent;
                border-bottom: none;
            }
        }
    }
    .left-block {
        width: 240px;
        align-items: center;
        display: flex;
        // background: #0b86c1;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            background: transparent;
            padding-left: 20px;
            width: 320px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            background: transparent;
            padding-left: 20px !important;
            width: 320px;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            background: transparent;
            padding-left: 40px;
            width: 270px;
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
            background: transparent;
            padding-left: 20px;
            width: 320px;
        }
        .logo {
            padding: 0px 28px;
            height: 25px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                // padding: 19px 10px;
                padding: 0px 10px;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                // padding: 19px 10px !important;
                padding: 0px 10px !important;
            }
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                // padding: 19px 10px;
                padding: 0px 10px;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                // padding: 19px 10px;
                padding: 0px 10px;
            }
        }
        // login header stylings
        &.login {
            background: #ffffff;
        }
    }
    .right-block {
        width: calc(100% - 240px);
        padding: 0px 40px;
        background: #ffffff;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            justify-content: flex-end !important;
            padding: 0px 20px;
        }
        @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
            justify-content: flex-end !important;
            padding: 0px 20px !important;
        }
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            justify-content: flex-end !important;
            padding: 0px 30px;
        }
        @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
            justify-content: flex-end !important;
            padding: 0px 20px;
        }
        @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
            justify-content: flex-end !important;
            padding: 0px 20px;
        }
        .navbar {
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                display: none;
            }
            @media only screen and (min-device-width: 769px) and (max-device-width: 950px) and (orientation: landscape) {
                display: none;
            }
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                display: none;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                display: none;
            }
        }
        .navbar {
            .nav-link {
                font-size: 12px;
                color: $color-grey;
                margin-right: 14px;
                padding: 6px 12px !important;
                &:hover {
                    border-radius: 50px !important;
                    background-color: #e3eefc !important;
                    color: #074aa3;
                }
                &.dropdown-toggle::after {
                    margin-bottom: -2px !important;
                }
            }
            .nav-link[aria-expanded="true"] {
                border-radius: 50px !important;
                background-color: #e3eefc !important;
            }
            .active {
                border-radius: 50px !important;
                background-color: #e3eefc !important;
                color: #074aa3;
            }
        }
    }
    // .icon-notification {
    //     padding: 0px 30px;
    //     @media screen and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 767px) {
    //         padding: 0px 14px;
    //     }
    //     @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 767px) {
    //         padding: 0px 14px;
    //     }
    // }
    .profile-section {
        .subtitle-small {
            color: $color-blue;
        }
        .subtitle-extra-small {
            color: $color-grey1;
        }
    }
    .search-section {
        padding: 0px 40px;
        background: #ffffff;
        align-items: center;
        .search-bar-icon {
            width: 20px;
            height: 20px;
            &.cancel {
                width: 12px;
                height: 12px;
            }
        }
        .auto-complete {
            width: 100%;
            .search-bar {
                width: 100%;
                border: none !important;
                margin: 0px 12px;
                width: calc(100% - 50px);
                &:focus-visible,
                &:focus {
                    outline: none !important;
                }
                background: transparent;
            }
            .dropdown-val {
                padding: 5px 20px 5px;
                border-radius: 10px;
                border: solid 1 px #c2e2f2;
                background-color: #fff;
                position: absolute;
                left: 0;
                border: solid 1px #c2e2f2;
                box-shadow: 0px 6px 10px #d5e2f2;
                width: 100%;
                top: 30px;
                .autocomplete-list {
                    font-family: "Lexend" !important;
                    font-size: 12px;
                    font-weight: normal;
                    letter-spacing: -0.24px;
                    color: #7d7d8c;
                    margin: 15px 0px;
                    &:hover {
                        color: #074aa3;
                    }
                    a{
                        color: #7d7d8c;
                        &:hover {
                            color: #074aa3;
                        } 
                    }
                }
            }
        }
    }
}

@keyframes fadeInSlide {
    0% {
        opactity: 0;
        transform: translateX(800px);
    }
    100% {
        opactity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOutSlideDown {
    100% {
        opactity: 1;
        transform: translateX(0);
    }
    0% {
        opactity: 0;
        transform: translateX(800px);
    }
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}