// .popover-block-container {
//   .popover-button {
//     background: none;
//     border: none;
//   }
//   .popover-body {
//     background: #ffe8e6;
//   }
// }

// .popover {
//     background: #FFE8E6;
//     border: 1px solid #FFE8E6;
//     padding: 10px 8px;
//     font-size: 12px;
//     color: #51515C;
//     max-width: auto !important;
//     top: 4px;
//     box-shadow: 6px 6px 30px #FF52520F;
//     border-radius: 10px;
//     .arrow::after,
//     .arrow::before {
//         border-bottom-color: #FFE8E6;
//     }
//     a {
//         color: #51515c;
//         text-decoration: underline;
//         padding-left: 22px;
//         &:hover {
//             color: #51515c !important;
//             text-decoration: underline;
//         }
//     }
//     .popover-body {
//         padding: 0px;
//     }
// }

.popover-block-container {
  .popover-button {
    background: none;
    border: none;
    margin: 0px 24px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    position: relative;
    &.noti-hightlight-show {
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        right: 7px;
        display: block;
        height: 7px;
        width: 7px;
        border-radius: 10px;
        background-color: #ff382b;
        background: transparent
          linear-gradient(180deg, #ff382b 0%, #b50a0a 100%) 0% 0% no-repeat
          padding-box;
      }
    }
    .icon {
      margin: auto;
    }
    &.popover-triggered {
      background-color: #e3eefc;
    }
  }
}

.popover {
  z-index: 999;
  background: $color-white;
  border: 1px solid #529aff29;
  font-size: 12px;
  color: #51515c;
  // left: -9%!important;
  box-shadow: 6px 6px 30px #529aff29;
  border-radius: 10px;
  margin-left: -244px;
  overflow: hidden;
  .arrow {
    display: none;
  }
  a {
    color: #51515c;
    text-decoration: underline;
    padding-left: 22px;
    &:hover {
      color: #51515c !important;
      text-decoration: underline;
    }
  }
  .popover-body {
    padding: 0px;
  }
}

.popover-custom-content {
  .drop-head {
    padding: 18px 20px;
    color: $color-primary;
    margin-bottom: 0px;
  }
  .drop-list {
    padding-bottom: 18px;
    max-height: 350px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1d6f2;
      border-radius: 10px;
    }
    li {
      padding: 13px 20px 0px 20px;
      .each-list-content {
        color: #737896;
        position: relative;
        font-size: 11px;
        padding-left: 18px;
        padding-bottom: 13px;
        margin-bottom: 0px;
        &::after {
          content: "";
          height: 1px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0px;
          background-color: $color-light-blue1;
          display: block;
        }
      }
      &.highlighted-bg {
        background-color: #f3f9ff;
        .each-list-content {
          &::before {
            content: "";
            height: 8px;
            width: 8px;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 13px;
            margin-top: auto;
            margin-bottom: auto;
            background-color: $color-primary;
            display: block;
            border-radius: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .popover {
    position: fixed !important;
    top: 60px !important;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    max-width: 100%;
    transform: none !important;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100% - 60px);
    border-radius: 0px;
    overflow: auto;
    // &::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    //   background-color: transparent;
    // }
    // &::-webkit-scrollbar {
    //   width: 4px;
    //   height: 4px;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: #c1d6f2;
    //   border-radius: 10px;
    // }
  }

  .popover-custom-content {
    .drop-list {
      max-height: 100%;
      li {
        padding-top: 23px;
        .each-list-content {
          padding-bottom: 23px;
        }
        &.highlighted-bg {
          .each-list-content {
            &::before {
              bottom: 23px;
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 991px) {
  .popover-block-container {
    .popover-button {
      margin: 0px 8px;
      &.popover-triggered {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}